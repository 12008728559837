.lesson-content {
    padding-bottom: 2rem;

    @include bp-gte(md) {
        padding-bottom: 3rem;
    }

    .picture {
        width: 100%;
        height: auto;
        max-width: rem(900px);
        margin: 0 auto;
        display: block;
    }


    &__score {
        position: relative;
        margin-top: rem(30px);
        padding-top: rem(20px);
        padding-bottom: rem(20px);

        @include bp-gte(md) {
            margin-top: rem(18px);
            padding-bottom: rem(60px);
        }

        > svg {
            @extend .picture;
            width: 100%;
        }
    }

    img {
        @extend .picture;
    }

    i {
        font-size: rem(50px);
        font-style: normal;
        line-height: 0;
    }
}

.exercise-content,
.lesson-content {
    h3 {
        font-weight: bold;
    }

    h5 {
        margin-top: 3rem;
    }

    ol {
        display: block;
        list-style-type: decimal;
        padding-left: rem(24px);
    }

    li {
        display: list-item;
        list-style: decimal;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 1px;
        margin: 0 0 .5rem;
        font-weight: normal;
    }

    code {
        border-left: #ddd solid 5px;
        font-size: rem(14px);
        display: inline-block;
        padding: rem(9.5px);
        font-size: rem(13px);
        line-height: rem(20px);
        background-color: #f5f5f5;
        border: rem(1px) solid #ccc;
        border: rem(1px) solid rgba(0, 0, 0, 0.15);
        border-radius: rem(4px);
    }

    .piano-t-s {
        max-width: rem(330px);
    }

    .piano-icon {
        width: rem(33px);
        margin-left: rem(4px);
        margin-right: rem(4px);
    }

    .musifont-section-container {
        margin: rem(16px) 0 rem(32px);
    }

    .musifont-section {
        font-size: rem(24px);
        text-align: center;

        [class^="musisync-"] {
            font-size: rem(45px);
            line-height: 1;
        }
    }

    .cycle-fifth {
        font-size: rem(24px);

        > div {
            margin-bottom: rem(12px);
        }

        i {
            font-size: inherit;
        }
    }

    .center-stave {
        text-align: center;

        img {
            max-width: rem(530px);
        }
    }

    table {
        width: 100%;
        border: 1px solid $palette-clouds;

        thead {
            font-weight: bold;
            text-align: left;
        }

        th {
            text-align: left;
            font-size: rem(18px);
            border-bottom: 1px solid $palette-clouds;
        }

        tbody {
            tr:hover,
            tr:active,
            tr:focus {
                background-color: inherit;
            }

            tr:nth-child(odd) {
                background-color: #eee;

                tr:hover,
                tr:active,
                tr:focus {
                    background-color: #eee;
                }
            }

            td {
                border: 0;
            }
        }
    }
}
