/* Generated by Glyphter (http://www.glyphter.com) on  Sun Jul 17 2016*/
@font-face {
    font-family: 'musicFont';
    src: url('../fonts/MusiSync.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.icon {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before{
        display: inline-block;
        font-family: 'musicFont';
        font-style: normal;
        font-weight: normal;
        font-size: 3em;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale
    }
}

.icon-sharp:before{content:'\0042';}
.icon-flat:before{content:'\62';}
// treble clef '\0047'
// note \71
