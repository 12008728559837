.player-module-toggle {
    position: relative;
    display: inline-block;

    &__button {
        position: relative;
        width: rem(40px);
        height: rem(40px);
        border-radius: 50%;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 0px 0px 0px rgba(0, 0, 0, 0.16);
        transition: box-shadow 0.1s ease-in-out, 0.2s background-color linear;
        color: $palette-red;

        &:hover,
        &:active {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
        }

        .player-module-toggle--active & {
            background-color: $palette-red;
            color: $palette-off-white;
        }

        .volume-up,
        .volume-off {
            position: absolute;
            top: rem(4px);
            left: 0;
            right: 0;
            font-size: rem(31px);
            bottom: 0;
            transition: opacity 0.1s;
        }

        .volume-up {
            opacity: 0;
        }

        .volume-off {
            opacity: 1;
        }

        &.open{

            .volume-up {
                opacity: 1;
            }

            .volume-off {
                opacity: 0;
            }

            span{
                background-color: transparent;

                &:before{
                    transform: translateY(0) rotate(45deg);
                }

                &:after{
                    transform: translateY(0) rotate(-45deg);
                }
            }
        }
    }
}
