.newsletter {
    text-align: center;
    height: 100%;

    @include bp-gte(lg) {
        text-align: left;
    }

    &__page {
        height: 100%;
    }

    &__column {
        max-width: rem(630px);
        margin: 0 auto;
    }

    &__form {
        margin-bottom: rem(12px);

        .mce_inline_error {
            margin: 0!important;
        }

        .button-simple {
            margin-top: rem(8px);
        }
    }

    &__logo {
        width: rem(120px);
        position: relative;

        @include bp-gte(lg) {
            position: absolute;
            top: rem(16px);
            right: rem(16px);
        }
    }
}
