.info-icon {
    display: inline-block;
    font-size: rem(18px);
    width: rem(18px);
    line-height: 1;
    position: relative;

    &__icon {
        color: $palette-wet-asphalt;
    }

    &__dropdown {
        position: absolute;
        bottom: rem(-11px);
        left: rem(-8px);
        height: 0;
        width: 0;

        &--open {
            display: inline-block;
        }

        &__arrow {
            width: 0;
            height: 0;
            position: absolute;
            border-left: rem(6px) solid transparent;
            border-right: rem(6px) solid transparent;
            z-index: 1;

            &--up {
                top: rem(-6px);
                left: rem(10px);
                display: block;
                border-bottom: rem(6px) solid $palette-midnight-blue;
            }
        }

        &__content {
            text-align: left;
            width: 17rem;
            position: relative;
            background-color: $palette-wet-asphalt;
            color: $palette-white;
            z-index: 3;
            padding: rem(8px);
            position: absolute;
            left: rem(0px);
            border: 2px solid $palette-midnight-blue;
            border-radius: $page-border-radius;

            h5 {
                font-size: rem(16px);
            }

            p {
                margin-bottom: 0;
                font-size: rem(13px);
            }
        }
    }
}
