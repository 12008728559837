.menu-notification {
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 2px;
        background-color: $palette-red;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}

.user-dropdown {

    &__button {
        @extend .button-simple-font;
        text-transform: lowercase;
        min-width: initial;
        width: rem(42px);
        height: rem(42px);
        font-size: rem(24px);
        border-radius: 50%;
        margin-right: 0;

        @include bp-gte(md) {
            width: initial;
            font-size: rem(16px);
            padding: rem(8px) rem(12px);
            border-radius: 0;
        }

        &.has-notification {
            @extend .menu-notification;
        }
    }

    &__link-container {
        padding: 0;
    }

    &__link {
        padding: 0 rem(16px);
        width: 100%;
        height: 100%;
        display: block;

        &.has-notification {
            @extend .menu-notification;

            &:before {
                right: 12px;
                top: 8px;
                left: auto;
            }
        }
    }
}
