.profile {

    &__info-icon {
        position: absolute;
        top: rem(10px);
        right: rem(12px);
        z-index: 5;
    }

    &__connections {

        &__invite-form {
            width: 100%;
            position: relative;
        }
    }

    &__connection-requests,
    &__connections {

        &__title {
            padding: rem(12px) rem(12px) rem(8px);
            margin-bottom: 0;
            border-bottom: 1px solid $palette-clouds;
        }
    }

    &__badge-page {
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        padding-left: rem(8px);
        padding-right: rem(8px);

        @include bp-gte(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__badge-list {
        margin-left: rem(-8px);
    }

    &__badge {
        display: inline-block;
        width: rem(100px);
        height: rem(100px);
        margin: rem(8px);
    }

    &__page-item-left {
        padding: 0;
        margin-right: 0;

        @include bp-gte(lg) {
            margin-right: 24px;
        }
    }

    &__back-colour {
        background-color: $palette-blue;
        height: 125px;
        position: relative;

        &__username {
            color: $palette-white;
            text-align: center;
            font-size: rem(24px);
            padding-top: rem(24px);
        }
    }

    &__profilePic {
        background-color: $palette-off-white;
        border-radius: 50%;
        width: 126px;
        height: 126px;
        position: absolute;
        bottom: -64px;
        left: 0;
        right: 0;
        margin: 0 auto;
        overflow: hidden;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px;

        &__pic {
            position: absolute;
            width: 100%;
            height: auto;
            z-index: 1;
            top: 0;
        }

        &__overlay {
            line-height: 126px;
            background-color: rgba($palette-black, 0.6);
            z-index: 2;
            height: 100%;
            opacity: 0;
            display: block;
            position: relative;
            visibility: visible;

            &:hover,
            &:active,
            &:focus {
                opacity: 1;
            }

            &:before {
                content: "Change Picture";
                text-align: center;
                color: $palette-white;
            }
        }
    }

    &__details {
        position: relative;
        margin-top: 64px;
        @extend .page-lr-pad;
        padding-top: rem(16px);


        @include bp-gte(md) {
            padding: rem(16px) rem(16px) rem(32px);
        }

        li {
            line-height: 1;
            height: rem(32px);
        }
    }

    &__detail {
        display: block;
        position: relative;
        display: block;
        font-size: rem(20px);
        text-align: center;

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .svg-badge {
            display: inline-block;
            background-image: url(../svgs/badge-icon-grey.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: rem(18px);
            height: rem(18px);
        }

        &__text {
            display: block;
            position: relative;
            margin-left: rem(32px);
            text-transform: capitalize;
            text-align: left;
        }

        &__icon {
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            width: 28px;
        }
    }

    &__activity {
        padding: 0;

        @include bp-gte(md) {
            padding-left: rem(32px);
            padding-right: rem(32px);
        }
    }
}
