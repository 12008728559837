$header-spacing: 10px !default;

.header {
    display: block;
    position: absolute;
    z-index: $header-z-index;
    height: $navigation-mob-height;
    width: 100%;
    background-color: $palette-blue;

    @include bp-gte(md) {
        position: fixed;
        height: $navigation-height;
    }
    // box-shadow: 0 1px 4px rgba(0,0,0,.15);
    // transform: translate3d(0, 0, 0);
    // transition: transform .25s cubic-bezier(.2,.3,.25,.9);

    // .musie-app.open & {
    //     position: relative;
    // }

    // @include bp-lte(sm) {
    //     .musie-app.open & {
    //         transform: translate3d(280px, 0, 0);
    //     }
    // }

    &__page {
        height: 100%;
    }

    &__spinner {
        width: 100%;
        position: Absolute;
        top: rem(12px);
        left: 49%;
    }

    &__list {
        display: inline-block;
        height: 100%;

        &--left {
            float: left;
        }

        &--right {
            float: right;
        }

        &__item {
            height: 100%;
            color: $palette-white;
            margin-right: $header-spacing;
            position: relative;
            vertical-align: top;

            &.log-in {
                margin-left: rem(12px);
            }

            &--player-toggle {
                padding-top: rem(6px);
            }

            &:last-child {
                @include bp-gte(md) {
                    margin-right: 0;
                }
            }

            &__image {
                width: rem(46px);
                display: inline-block;
                bottom: -1px;
                position: relative;
            }

            &__text {

                &--icon {
                    margin-left: rem(8px);
                }
            }

            .link {
                @extend .button-simple-font;
                position: relative;
                display: inline-block;
                height: 100%;
                padding: 0px rem(16px);
                text-shadow: 0 0 1px rgba(255,255,255,0.1);
                outline: none;
                color: $palette-off-white;
                background-color: darken($palette-blue, 5%);

                transition: background-color 0.1s ease-in-out;

                &.icon {
                    font-size: 1.4em;
                }

                &:hover, &:active, &--menu.open, &.active {
                    background-color: darken($palette-blue, 15%);
                }

                @include bp-gte(md) {
                    height: initial;
                    padding: rem(7px) rem(20px);
                    border-radius: rem(40px);
                }

                i {
                    // position: relative;
                    // top: 2px;
                }
            }
        }
    }
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
