@font-face {
    font-family: "MusiSync";
    src: url("../fonts/MusiSync.ttf");
}

[class^="musisync-"], [class*=" musisync-"] {
    font-family: 'MusiSync';
    font-size     : 20pt;
}

.musisync-wholeNote:before {
    content: "\0077";
}

.musisync-halfNote:before {
    content: "\0068";
}

.musisync-quarterNote:before {
    content: "\0071";
}

.musisync-eighthNote:before {
    content: "\0065";
}

.musisync-sixteenthNote:before {
    content: "\0073";
}

.musisync-wholeRest:before {
    content: "\0057";
}

.musisync-halfRest:before {
    content: "\0048";
}

.musisync-quarterRest:before {
    content: "\0051";
}

.musisync-eightRest:before {
    content: "\0045";
}

.musisync-sixteenthRest:before {
    content: "\0053";
}

.musisync-bar:before {
    content: "\'";
}

.musisync-flat:before {
    content: "\0062";
}

.musisync-natural:before {
    content: "\½" ;
}

.musisync-sharp:before {
    content: "\0042";
}

.musisync-doubleFlat:before {
    content: "\0062 \0062";
}

.musisync-doubleSharp:before {
    content: "\0058";
}

.musisync-beam:before {
    content: "n";
}

.musisync-dot:before {
    content: "j"
}

.musisync-tie:before {
    content: "¥"
}

.musisync-semibreve:before {
    content: "w";
}

.musisync-minim:before {
    content: "h";
}

.musisync-crotchet:before {
    content: "q";
}

.musisync-quaver:before {
    content: "e";
}

.musisync-semiquaver:before {
    content: "s";
}

.musisync-2-4:before {
    content: "@";
}

.musisync-3-4:before {
    content: "#";
}

.musisync-4-4:before {
    content: "$";
}

.musisync-c:before {
    content: "c";
}

.musisync-dot-minim:before {
    content: "d";
}
