// @s - 400px
// @m - 600px
// @l - 900px
// @xl - 1008px
// @xxl - 1280px

$breakpoint-sm: 400px; // 1
$breakpoint-md: 600px; // 2
$breakpoint-lg: 900px; // 3
$breakpoint-xl: 1008px; // 4
$breakpoint-xxl: 1280px; // 5

$breakpoint1: 'only screen and (min-width:#{$breakpoint-sm}) and (max-width:#{$breakpoint-md - 1})';
$breakpoint2: 'only screen and (min-width:#{$breakpoint-md}) and (max-width:#{$breakpoint-lg - 1})';
$breakpoint3: 'only screen and (min-width:#{$breakpoint-lg}) and (max-width:#{$breakpoint-xl - 1})';
$breakpoint4: 'only screen and (min-width:#{$breakpoint-xl}) and (max-width:#{$breakpoint-xxl - 1})';
$breakpoint5: 'only screen and (min-width:#{$breakpoint-xxl})';

$breakpoint1-gte: 'only screen and (min-width:#{$breakpoint-sm})';
$breakpoint2-gte: 'only screen and (min-width:#{$breakpoint-md})';
$breakpoint3-gte: 'only screen and (min-width:#{$breakpoint-lg})';
$breakpoint4-gte: 'only screen and (min-width:#{$breakpoint-xl})';
$breakpoint5-gte: 'only screen and (min-width:#{$breakpoint-xxl})';

$breakpoint1-lte: 'only screen and (max-width:#{$breakpoint-md - 1})';
$breakpoint2-lte: 'only screen and (max-width:#{$breakpoint-lg - 1})';
$breakpoint3-lte: 'only screen and (max-width:#{$breakpoint-xl - 1})';
$breakpoint4-lte: 'only screen and (max-width:#{$breakpoint-xxl - 1})';

@mixin bp($breakpoint) {
    @if $breakpoint == "sm" {
        @media #{$breakpoint1} { @content; }
    }

    @if $breakpoint == "md" {
        @media #{$breakpoint2} { @content; }
    }

    @if $breakpoint == "lg" {
        @media #{$breakpoint3} { @content; }
    }

    @if $breakpoint == "xl" {
        @media #{$breakpoint4} { @content; }
    }

    @if $breakpoint == "xxl" {
        @media #{$breakpoint5} { @content; }
    }
}

@mixin bp-gte($breakpoint) {
    @if $breakpoint == "sm" {
        @media #{$breakpoint1-gte} { @content; }
    }

    @if $breakpoint == "md" {
        @media #{$breakpoint2-gte} { @content; }
    }

    @if $breakpoint == "lg" {
        @media #{$breakpoint3-gte} { @content; }
    }

    @if $breakpoint == "xl" {
        @media #{$breakpoint4-gte} { @content; }
    }

    @if $breakpoint == "xxl" {
        @media #{$breakpoint5-gte} { @content; }
    }
}

@mixin bp-lte($breakpoint) {
    @if $breakpoint == "sm" {
        @media #{$breakpoint1-lte} { @content; }
    }

    @if $breakpoint == "md" {
        @media #{$breakpoint2-lte} { @content; }
    }

    @if $breakpoint == "lg" {
        @media #{$breakpoint3-lte} { @content; }
    }

    @if $breakpoint == "xl" {
        @media #{$breakpoint4-lte} { @content; }
    }
}
