.exercise-content {
    padding: 0 1rem 1rem;

    @include bp-gte(md) {
        padding: 0 3rem 3rem;
    }

    @include bp-gte(lg) {
        padding: 0 7rem 3rem;
    }

    &__title {
        text-transform: capitalize;
    }

    &__download {

        i {
            font-size: rem(70px);
        }

        &__link {
            background-color: $palette-clouds;
            color: $palette-yellow;
            text-align: center;
            display: block;
            max-width: rem(300px);
            border-radius: $page-border-radius;
            margin: rem(35px) auto rem(55px);
            padding-left: rem(40px);
            padding-right: rem(40px);
            height: rem(200px);
            padding-top: rem(53px);

            strong {
                display: block;
            }
        }
    }
}
