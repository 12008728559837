.design-page {
    background-color: $palette-white;

    &__section {

    }

    &__container {
        padding: rem(48px) 0;
        border-bottom: 1px solid $palette-clouds;

        p {
            padding-bottom: rem(16px);
            max-width: rem(600px);
        }
    }

    &__logo-section {
        margin-bottom: rem(24px);
    }

    &__logo {
        width: 33%;
        float: left;
    }

    &__colour-item {
        color: $palette-white;
        text-transform: uppercase;
        padding-right: rem(24px);
        margin-bottom: rem(12px);

        @include bp-lte(sm) {
            &:nth-child(2n) {
                padding-right: 0;
            }
        }

        @include bp-gte(md) {
            &:nth-child(4n) {
                padding-right: 0;
            }
        }

        &__content {
            text-align: center;
        }

        &__colours {
            border-radius: $page-border-radius;
            overflow: hidden;

            &--white {
                border: 1px solid $palette-text;
            }
        }

        &__colour {
            display: block;
            width: 100%;
            padding: rem(18px);
            line-height: 0;

            strong {
                transition: opacity $transition;
                opacity: 0;
            }

            &.is-active {
                line-height: 1;

                strong {
                    opacity: 1;
                }
            }

            &--yellow {

                &-light {
                    background-color: $palette-yellow-light;
                }

                &-hex {
                    background-color: $palette-yellow;
                }

                &-hex {
                    background-color: $palette-yellow;
                }

                &-dark {
                    background-color: $palette-yellow-dark;
                }


            }

            &--green {

                &-light {
                    background-color: $palette-green-light;
                }

                &-hex {
                    background-color: $palette-green;
                }

                &-dark {
                    background-color: $palette-green-dark;
                }
            }

            &--blue {

                &-light {
                    background-color: $palette-blue-light;
                }

                &-hex {
                    background-color: $palette-blue;
                }

                &-dark {
                    background-color: $palette-blue-dark;
                }
            }

            &--red {

                &-light {
                    background-color: $palette-red-light;
                }

                &-hex {
                    background-color: $palette-red;
                }

                &-dark {
                    background-color: $palette-red-dark;
                }
            }

            &--purple {

                &-light {
                    background-color: $palette-purple-light;
                }

                &-hex {
                    background-color: $palette-purple;
                }

                &-dark {
                    background-color: $palette-purple-dark;
                }
            }

            &--orange {

                &-light {
                    background-color: $palette-orange-light;
                }

                &-hex {
                    background-color: $palette-orange;
                }

                &-dark {
                    background-color: $palette-orange-dark;
                }
            }

            &--grey {

                &-light {
                    color: $palette-text;
                    background-color: $palette-clouds;
                }

                &-hex {
                    color: $palette-text;
                    background-color: $palette-page;
                }

                &-dark {
                    color: $palette-text;
                    background-color: $palette-silver;
                }
            }

            &--dark {

                &-light {
                    background-color: $palette-asbestos;
                }

                &-hex {
                    background-color: $palette-wet-asphalt;
                }

                &-dark {
                    background-color: $palette-midnight-blue;
                }
            }

            &--black {

                &-light {
                    background-color: $palette-black;
                }

                &-hex {
                    background-color: $palette-black;
                }

                &-dark {
                    background-color: $palette-black;
                }
            }

            &--white {

                &-light {
                    background-color: $palette-white;
                }

                &-hex {
                    color: $palette-text;
                    background-color: $palette-white;
                }

                &-dark {
                    background-color: $palette-white;
                }
            }
        }
    }

    &__typography-content {

        &__item {
            padding: rem(24px);
            display: block;

            h1, h2, h3, h4, h5, p {
                margin-bottom: 0;
                padding-bottom: 0;
            }

            &:nth-child(odd) {
                background-color: $palette-clouds;
                border-radius: $page-border-radius;
            }
        }
    }

    &__bird-container {
        text-align: center;
    }

    &__bird {
        display: inline-block;
        max-width: rem(240px);
    }

    &__icons {

    }

    &__icon {
        float: left;
        width: rem(100px);
        height: auto;
        padding: 0 rem(12px) rem(12px) 0;

        &__img {

        }
    }

    &__button {
        margin-right: rem(16px)!important;
        margin-bottom: rem(16px);
        display: inline-block;
    }

    &__video-section iframe {
        width: 100%;
        max-width: 40rem;
        margin: 0 auto;
    }
}
