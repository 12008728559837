.loading {
    float: left;
    width: 100%;

    &__icon {
        display: block;
        height: 100px;
        width: 100px;
        margin: 0 auto;
        background-color: rgba($palette-off-white, 0.7);
        background-image: url('../svgs/loading.svg');
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 2;
    }
}
