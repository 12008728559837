$sub-nav-height: 55px!default;

.sub-nav {
    // margin-top: 10px;
    position: fixed;
    width: 100%;
    height: $sub-nav-height;
    border-bottom: 1px solid $palette-emerald-subtle;
    background-color: $palette-clouds;
    font-size: 0.8em;
    text-transform: uppercase;
    z-index: 3;

    .page {
        height: 100%;
    }

    &__dropdown {
        display: block;
        text-align: center;
        line-height: 54px;
        margin: 0 auto;
        width: 100%;
        font-size: rem(18px);
        border-bottom: 1px solid $palette-silver;
    }

    &__list {
        display: block;
        height: 44px;

        @include bp-gte(lg) {
            height: 100%;
        }

        &.is-toggled {
            display: block;
        }

        &__item {
            height: 100%;
            background-color: rgba($palette-clouds, 0.95);
            overflow: hidden;
            vertical-align: top;
            transition: border-color 0.2s ease-out;
            border-bottom: 1px solid $palette-silver;
            display: block;

            &:last-child {
                border-right: 1px solid $palette-emerald-subtle;
            }

            @include bp-lte(md) {
                &.active {
                    background-color: rgba($palette-emerald-subtle, 0.95);
                }
            }

            @include bp-gte(lg) {
                float: left;
                display: inline-block;
                margin-bottom: 1px;
                border-bottom: 2px solid $palette-clouds;
                border-left: 1px solid $palette-emerald-subtle;

                &.active {
                    border-bottom: 2px solid $palette-emerald-subtle;
                }
            }

            &__link {
                padding: 0 12px;
                width: 100%;
                text-align: center;
                text-transform: uppercase;

                @include bp-gte(lg) {
                    width: 100px;
                }

                .vertical-block__middle {
                    position: relative;
                    bottom: -2px;
                }
            }
        }
    }
}
