.meet-the-team-page {

    &__page {

        @include bp-gte(md) {
            padding-top: 6.25rem;
        }

    }

    &__members-list {
        padding-bottom: rem(104px);
        @extend .page-lr-pad;
    }

    &__page-item {
        padding: 0;
        overflow: hidden;
    }
}
