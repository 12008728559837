.progress-page {

    &__content {
        padding: 1rem;

        &--banner {
            padding-top: rem(80px);
        }
    }

    &__course-item {
        position: relative;

        &__overlay {
            opacity: 0.7;
            visibility: visible;
            background-color: $palette-white;
        }
    }

    &__topic-groups {

    }

    &__topic-group {
        display: block;
        margin-bottom: rem(16px);
    }

    &__topics {
        h5 {
            margin-bottom: 0.6rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
        position: relative;

        // @include bp-gte(md) {
        //     top: rem(-16px);
        //     left: rem(-16px);
        // }
        //
        // &__icon {
        //     width: 3rem;
        //     height: 3rem;
        //     display: inline-block;
        //     vertical-align: top;
        // }

        &__text {
            display: inline-block;
            vertical-align: top;

            h4 {
                margin-bottom: 0;
                text-transform: uppercase;
            }

            span {
                display: block;
            }
        }
    }

    &__profile-block {
        height: 9.5rem;
        display: block;
        padding: rem(12px);
        font-size: 1.2rem;
        line-height: 1.6;
        font-weight: bold;
        background-color: $palette-wet-asphalt;
        color: $palette-white;
        border-radius: $page-border-radius;
        cursor: default;

        &__pic {
            background-color: $palette-white;
            border-radius: 50%;
            width: 65%;
            height: auto;
            margin: 0 auto;
            overflow: hidden;
        }

        &__small-text {
            font-size: rem(10px);
            margin-top: rem(8px);
        }

        &__image {
            background-image: url(../svgs/bird2.svg);
            background-repeat: no-repeat;
            background-position: -60px 60px;
            background-size: 53%;
        }

        li {
            line-height: 1;
        }

        &__details {
            position: relative;
            top: 4px;
        }

        &__detail {
            display: block;
            font-size: rem(16px);
            padding-bottom: rem(12px);
            position: relative;

            &:last-child {
                padding-bottom: 0;
            }

            .svg-badge {
                display: inline-block;
                background-image: url(../svgs/badge-icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: rem(18px);
                height: rem(18px);
            }

            &__text {
                display: block;
                position: absolute;
                left: rem(28px);
                top: 0;
                text-transform: capitalize;
            }

            &__icon {
                text-align: center;
                width: rem(20px);
            }
        }
    }

    &__upsell {
        min-height: 9.5rem;
        background-image: url(../svgs/bird2.svg);
        background-repeat: no-repeat;
        background-position: -60px 60px;
        background-size: 53%;
        display: block;
        padding: 1.25rem 1.25rem 1.25rem 4.5rem;
        text-align: right;
        font-size: 1.2rem;
        line-height: 1.6;
        font-weight: bold;
        background-color: $palette-wet-asphalt;
        color: $palette-white;
        border-radius: $page-border-radius;

        @include bp-gte(xl) {
            background-position: -60px 49px;
            padding: 2rem 1.25rem 2rem 6.5rem;
        }

        &__small-text {
            font-size: rem(10px);
            margin-top: rem(8px);
        }
    }

    &__upsell-cloudpiano-wrapper {
        background-color: $palette-white;
        background-image: linear-gradient(0deg, rgba(#F2F5F7,1) 0%, rgba(#7288A3,1) 100%);
        width: 100%;
        display: block;
        border-radius: $page-border-radius;
    }

    &__upsell-cloudpiano {
        min-height: 9.5rem;
        background-image: url(../svgs/cloudpiano.svg);
        background-repeat: no-repeat;
        background-size: 50%;
        text-align: right;
        font-size: 1.2rem;
        line-height: 1.6;
        font-weight: bold;
        color: $palette-white;

        background-position: -20px 38px;
        padding: 2rem 1.25rem 2rem 6.5rem;

        &__small-text {
            font-size: rem(10px);
            margin-top: rem(8px);
        }
    }

    &__grade-list-mobile-button {
        display: block;
        text-align: center;
        height: rem(56px);
        width: 100%;
        color: $palette-asbestos;

        h3 {
            position: relative;
            bottom: rem(-6px);
        }
    }

    &__grade-list {
        padding: 0;
        position: relative;

        @include bp-gte(lg) {
            padding: rem(32px) 0;
        }

        &__info-icon {
            display: inline-block;
            position: absolute;
            top: rem(6px);
            right: rem(10px);
        }
    }

    &__info-icon {
        display: inline-block;
        position: absolute;
        top: rem(16px);
        right: rem(16px);
    }

    &__grade-list-item {
        display: block;

        &:last-child .progress-page__grade {
            border-bottom: 1px solid $palette-clouds;
        }

        &.disabled {
            background-color: $palette-off-white;
            cursor: default;
        }
    }

    &__grade {
        display: block;
        padding: 8px 30px;
        border-top: 1px solid $palette-clouds;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        width: 100%;
        text-align: left;
        @extend .button-link;

        &.is-active {
            background-color: $palette-blue;
            color: $palette-white;
        }
    }

    &__topic-items-container {
        position: absolute;
        bottom: rem(-11px);
        left: 0;
        height: 0;
        width: 0;
        display: none;

        &--open {
            display: inline-block;
        }
    }

    &__topic-items-arrow {
        width: 0;
        height: 0;
        position: absolute;
        border-left: rem(10px) solid transparent;
        border-right: rem(10px) solid transparent;
        z-index: 1;


        &--up {
            top: rem(-10px);
            left: rem(50px);
            display: block;
            border-bottom: rem(10px) solid $palette-asbestos;
        }
    }

    &__topic-items {
        padding-left: 0;
        text-align: left;
        width: rem(350px);
        position: relative;
        background-color: white;
        z-index: 3;
        padding: rem(12px);
        position: absolute;
        left: rem(-108px);
        border: 3px solid $palette-asbestos;
        border-radius: $page-border-radius;
        background-color: $palette-clouds;

        @include bp-gte(md) {
            padding-left: rem(32px);
        }

        @include bp-gte(lg) {
            left: 0;
        }
    }

    &__topic {
        margin-bottom: rem(8px);
        position: relative;
        text-align: center;

        &.is-complete {
            .progress-page__topic__progress {
                display: block
            }
        }

        &__progress {
            background-color: $palette-green;
            height: rem(4px);
            position: absolute;
            display: none;
            top: 0;
            left: 0;
            right: 0;
        }

        &__dropdown {
            text-align: center;
            display: block;
            width: 100%;
            padding: rem(4px);
            font-size: rem(16px);
            @extend .button-link;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border: 0;
            height: rem(20px);
            border-top: 1px solid $palette-clouds;
            line-height: 0;

            &:hover,
            &:active,
            &:focus {
                background-color: $palette-clouds;
            }

            .fa {
                line-height: 0;
                top: rem(-2px);
                position: relative;
            }
        }

        &__overlay {
            display: none;
            background-color: rgba($palette-midnight-blue, 0.75);
            text-align: center;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            vertical-align: middle;
            color: $palette-off-white;
            border-radius: 3px;
            font-size: rem(16px);
            font-weight: bold;
            white-space: nowrap;
            transition: opacity $transition;

            .vertical-block__middle {
                position: relative;
                top: rem(-10px);
            }

            &__note {
                font-size: rem(13px);
                position: absolute;
                left: 0;
                right: 0;
                padding-top: 6px;
            }

            &__x {
                font-size: rem(40px);
            }
        }

        .progress-page__topic__overlay {
            display: inline-block;
            opacity: 0;
        }

        .touch &,
        &:hover,
        &:active,
        &:focus {
            .progress-page__topic__overlay {
                opacity: 1;
            }
        }

        &__main {
            display: inline-block;
            border-radius: $page-border-radius;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-size: rem(20px);
            margin: 0 auto;
            position: relative;
            max-width: rem(120px);
            width: 100%;
            height: rem(110px);
            line-height: 1;

            &__link {
                display: block;
                padding: rem(8px);
            }

            &__icon {
                display: none;
                right: rem(24px);
                top: rem(21px);
                position: absolute;
                height: rem(20px);
                width: rem(20px);
                color: $palette-green;

                &.is-complete {
                    display: inline-block;
                }
            }

            .vertical-layout {
                height: 100%;
            }
        }

        &__item {
            display: inline-block;
            display: block;
            margin-top: rem(5px);

            &--lesson {

            }

            &--quiz {

            }

            &:first-child {
                margin-top: 0;
            }

            &__link {
                display: block;
                position: relative;
            }

            &__check {
                opacity: 0;
                line-height: rem(23px);

                .progress-page__topic__item.is-complete & {
                    opacity: 1;
                }
            }
        }

        &__name {
            line-height: rem(24px);
            vertical-align: top;
            display: inline-block;
            padding-left: rem(32px);
        }

        &__badge-container {
            text-align: center;
            border-radius: 50%;
        }

        &__text-container {
            font-size: rem(14px);
            font-weight: bold;
        }

        &__badge {
            width: rem(40px);
            height: rem(40px);
            margin-bottom: rem(4px);
        }

        &__checked {
            background-color: $palette-white;
            display: inline-block;
            height: rem(24px);
            width: rem(24px);
            text-align: center;
            border: 1px solid $palette-clouds;
            color: $palette-green;
            margin-right: 8px;
            position: absolute;
            left: 0;
        }
    }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity .5s ease-in;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.example-abs-enter {
  opacity: 0.01;
  position: absolute;
}

.example-abs-enter.example-abs-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-abs-leave {
  opacity: 1;
}

.example-abs-leave.example-abs-leave-active {
  opacity: 0.01;
  transition: opacity .5s ease-in;
}

.example-abs-appear {
  opacity: 0.01;
}

.example-abs-appear.example-abs-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}
