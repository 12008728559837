.answer-area {
    padding: 0 rem(16px);

    @include bp-gte(md) {
        padding-bottom: rem(24px);
        padding-left: rem(16px);
        padding-right: rem(16px);
    }

    &__answer {
        text-align: center;
    }
}
