.exercise-page {

    &__next {
        position: absolute;
        right: 0;
        top: 2px;

        &.mdl-button--accent{
            color: $palette-silver;
        }

        &--active {
            color: $palette-white!important;
            background-color: darken($palette-emerald-dark, 10%);

            &:hover {
                background-color: $palette-emerald-dark;
            }
        }
    }

    &__next-prev {

    }

    &__progress {
        text-align: center;
        margin-bottom: rem(18px);
        position: relative;
    }

    &__list {
        display: inline-block;
        margin: rem(10px) rem(20px);
        text-align: center;

        &__item {
            width: rem(20px);
            height: rem(20px);
            border-radius: 50%;
            display: inline-block;
            margin-right: 8px;
            background-color: $palette-asbestos;
            cursor: pointer;

            &.is-active {
                background-color: $palette-blue;
            }
        }
    }

    &__bottom-panel {
        height: rem(54px);
    }
}
