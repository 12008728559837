.main-promo {
    color: $palette-asbestos;
    overflow: hidden;
    position: relative;
    text-align: left;
    background-color: $palette-white;
    background-image: url('../svgs/icons2.svg');
    background-repeat: no-repeat;
    background-size: 65% auto;
    background-position: right bottom;

    @include bp-gte(lg) {
        height: 100vh;
        min-height: rem(550px);
        max-height: rem(800px);
    }

    @include bp-gte(xxl) {
        background-size: 1000px auto;
    }

    // &__icons {
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    //     width: 65%;
    // }

    &__move-on {
        position: absolute;
        text-align: center;
        bottom: rem(28px);
        left: 0;
        right: 0;
        font-size: rem(28px);
        display: none;
        animation: glow 1.3s ease-out 1s;

        @include bp-gte(lg) {
            display: block;
        }

        .fa {
            background-color: white;
            border-radius: 50%;
            padding: rem(16px);
            font-size: rem(20px);
            box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
            cursor: pointer;
            transition: box-shadow $transition;

            &:hover {
                box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.2);
            }
        }
    }

    &__page {
        padding-top: rem(40px) + $navigation-height;
        padding-bottom: rem(25px);

        @include bp-gte(lg) {
            padding-top: rem(165px);
        }
    }

    p {
        font-size: rem(18px);
    }

    .cursor {
        margin-left: rem(-2px);
        animation: 1s blink infinite;
    }

    .tagline {
        min-height: rem(80px);
        margin-bottom: rem(24px);
        font-size: rem(20px);

        @include bp-gte(md) {
            min-height: auto;
            margin-bottom: rem(84px);
        }
    }

    @keyframes "blink" {
        from, to {
          opacity: 1;
        }
        15% {
          opacity: 1;
        }
        85% {
          opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }

    &__container {
        z-index: 2;
        position: relative;

        h2 {
            margin-bottom: rem(40px);
        }

        p {
            margin-bottom: rem(40px);
            padding-right: 0;

            @include bp-gte(lg) {
                padding-right: 21.25rem;
                margin-bottom: rem(60px);
            }
        }

        &__button {
            text-align: left;
            width: auto;
            text-transform: capitalize;
            font-weight: bold;
            font-size: rem(16px);
            padding: rem(15px) rem(20px);
        }
    }
}

@keyframes glow {
    from {color: $palette-asbestos;}
    50% {
        color: $palette-yellow;
    }
    to {color: $palette-asbestos;}
}
