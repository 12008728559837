.something-wrong {
    color: $palette-silver;
    font-size: rem(14px);
    margin-top: rem(8px);

    i {
        margin-right: rem(6px);
    }

    &:hover,
    &:active,
    &:focus {
        color: $palette-blue;
    }
}

.something-wrong-modal {
    min-height: rem(80px);
    width: rem(300px);

    @include bp-gte(sm) {
        width: rem(400px);
    }

    &__text-area {
        @extend .compose-input;
        resize: none;
    }

    &__whats-wrong {
        width: rem(300px);
        font-size: rem(16px);

        @include bp-gte(sm) {
            width: rem(400px);
        }
    }

    &__result {
        color: $palette-green;

        &--error {
            color: $palette-red;
        }

        i {
            font-size: rem(60px);
        }
    }

    &__send {

    }

    &__validation-error {
        color: $palette-red;
        text-align: left;
        margin-bottom: rem(4px);
    }

    &__send-button {
        float: right;
    }
}
