.topic-toolbar {
    display: block;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    bottom: rem(-12px);

    @include bp-gte(md) {
        bottom: rem(-4px);
    }

    &__right {
        display: block;
        position: relative;
        float: right;
        right: rem(4px);
        bottom: 0;

        @include bp-gte(md) {
            right: 0;
        }
    }

    &__progress {
        display: inline-block;
        text-align: center;
        // left: rem(32px);
        left: 0;
        right: 0;
        bottom: rem(12px);

        @include bp-gte(md) {
            bottom: rem(18px);
        }

        @include bp-gte(lg) {
            // left: rem(110px);
        }

        &__item {
            display: inline-block;
            vertical-align: bottom;
            margin-left: rem(4px);
            margin-bottom: rem(8px);

            &:first-child {
                margin-left: 0px;
            }

            &--review {

                & .topic-toolbar__progress__link {
                    color: $palette-white;
                    background-color: $palette-green;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: lighten($palette-green, 5%);
                    }
                }
            }
        }

        &__link {
            color: $palette-white;
            display: inline-block;
            width: rem(28px);
            height: rem(24px);
            line-height: rem(24px);
            background-color: $palette-asbestos;
            border-radius: 2px;
            transition: background-color $subtle-transition;

            &:hover,
            &:active,
            &:focus {
                background-color: lighten($palette-asbestos, 5%);
            }

            &--lesson {
                // background-color: $palette-yellow;
            }

            &--quiz {
                // background-color: #63757b;
            }

            .topic-toolbar__progress__item.is-active & {
                background-color: $palette-blue;
            }

            .topic-toolbar__progress__item.is-before & {
                background-color: $palette-blue;
            }

            // .topic-toolbar__progress__item.is-complete & {
            //     background-color: $palette-green;
            // }
            //
            // .topic-toolbar__progress__item.is-active.is-complete & {
            //     background-color: $palette-yellow;
            // }
        }
    }
}
