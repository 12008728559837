$player-control-width: rem(55px)!default;

.player {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 0.8em;

    @include bp-gte(md) {
        position: relative;
    }

    &__controls {
        height: 100%;

        &__progress {
            left: rem(16px);
            bottom: rem(-58px);
            font-size: rem(13px);
            position: absolute;

            @include bp-gte(md) {
                padding: 0;
                bottom: initial;
                position: static;
            }
        }

        &__action {
            text-align: center;

            &__item {
                height: 100%;
                width: $player-control-width;
                font-size: rem(24px);

                @include bp-gte(md) {
                    font-size: rem(16px);
                }
            }
        }

        &__scroll {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            @include bp-gte(md) {
                position: relative;
                top: initial;
                left: initial;
            }

            .bar {
                position: relative;
                margin: 0 auto;
                padding: rem(8px) 0;
                cursor: pointer;
                top: rem(-8px);

                @include bp-gte(md) {
                    padding: rem(18px) 0;
                }

                &__line {
                    height: rem(4px);
                    background-color: $palette-asbestos;

                    @include bp-gte(md) {
                        height: rem(2px);
                    }

                    &__progress {
                        background-color: lighten($palette-red, 10%);
                        height: 100%;
                        width: 0%;
                        position: relative;

                        &__handle {
                            display: none;
                            height: rem(16px);
                            width: rem(16px);
                            border-radius: 50%;
                            background-color: $palette-off-white;
                            border: 1px solid rgba($palette-silver, 0.7);
                            float: right;
                            position: relative;
                            top: rem(-7px);
                            cursor: pointer;
                            right: rem(-8px);

                            @include bp-gte(md) {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        &__volume {
            position: absolute;
            right: rem(16px);
            bottom: rem(8px);
            font-size: rem(24px);

            @include bp-gte(md) {
                right: 0;
                bottom: 0;
            }

            button {
                height: 100%;
            }
        }

        &__artwork {
            display: none;
            float: left;
            position: relative;
            margin-right: rem(8px);
            height: rem(40px);
            width: rem(40px);
            background-repeat: no-repeat;
            background-color: $palette-wet-asphalt;
            background-size: cover;

            @include bp-gte(md) {
                display: block;
            }
        }

        &__details {
            padding-top: rem(4px);

            @include bp-gte(md) {
                padding-top: 0;
                padding-left: rem(8px);
                padding-bottom: rem(16px);
            }

            &__title {
                display: block;
                white-space: nowrap;
                font-size: rem(16px);
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    color: lighten($palette-asbestos, 10%);
                }
            }

            &__artist {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: rem(12px);
                color: $palette-emerald-dark;

                &:hover {
                    color: lighten($palette-emerald-dark, 10%);
                }
            }

            &__artist,
            &__title {
                text-align: center;

                @include bp-gte(md) {
                    text-align: left;
                }
            }
        }
    }
}
