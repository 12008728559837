.connect-button {

    &__button {
        line-height: rem(46px);
        height: rem(46px);
        padding: 0 rem(28px);
        min-width: rem(160px);
        background-color: $palette-orange;
        border-radius: $page-border-radius;
        color: $palette-white;

        &--is-connected {
            background-color: $palette-silver;
            border: 3px solid $palette-orange;

            .connect-button__text {
                color: $palette-asbestos;
            }
        }

        .hover-show {
            display: none;
        }

        .hover-hide {
            display: inline-block;
        }

        &:hover,
        &:active,
        &:focus {
            .hover-hide {
                display: none;
            }

            .hover-show {
                display: inline-block;
            }
        }
    }
}
