.banner-top {
    position: relative;
    background-color: $palette-wet-asphalt;
    color: $palette-white;
    z-index: 10;
    font-size: rem(12px);
    display: none;

    &.active {
        display: block;
    }

    &__container {
        position: relative;
        padding: rem(12px) rem(48px);
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        color: $palette-white;
        text-align: center;
        line-height: 0;
        font-size: rem(32px);
        padding: 0 rem(16px);
    }
}
