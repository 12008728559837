button {
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
}

.button-link {
    border: 1px solid $palette-clouds;
    transition: 0.1s background-color ease-in-out;

    &:hover,
    &:active,
    &:focus,
    &.is-active {
        background-color: rgba($palette-off-white, 0.4);
    }
}

.button-simple-font {
    font-family: "Helvetica Neue",Arial,Helvetica,Verdana,sans-serif;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.01em;
}

.button-simple,
button.button-simple,
input.button-simple {
    @extend .button-simple-font;
    line-height: rem(36px);
    background-color: $palette-orange;
    border: 0 none;
    border-radius: 3px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    height: rem(36px);
    margin-right: 0;
    overflow: hidden;
    padding: 0 rem(18px);
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    transition: background-color $transition;

    &:hover,
    &:active,
    &:focus {
        background-color: $palette-orange-light;
    }

    &--gray {
        background-color: $palette-silver;
    }

    &--red {
        background-color: $palette-red;

        &:hover,
        &:active,
        &:focus {
            background-color: $palette-red-light;
        }
    }

    &--blue {
        background-color: $palette-blue;

        &:hover,
        &:active,
        &:focus {
            background-color: $palette-blue-light;
        }
    }

    &--green {
        background-color: $palette-green;

        &:hover,
        &:active,
        &:focus {
            background-color: $palette-green-light;
        }
    }
}

.btn {
    display: inline-block;
    vertical-align: top;
    width: rem(220px);
    color: #fff;
    // font-size: 17px;
    line-height: rem(21px);
    font-weight: bold;
    background: $palette-red;
    border-radius: 3px;
    padding: rem(12px) 0;
    font-weight: 500;
    border-bottom: 2px solid $palette-red-dark;
    text-align: center;

    &:hover, &:active, &:focus {
        cursor: pointer;
        background-color: $palette-red-light;
    }

    &--grey {
        background: $palette-clouds;
        border-bottom: 2px solid $palette-silver;
        color: $palette-asbestos;

        &:hover, &:active, &:focus {
            background: lighten($palette-clouds, 10%);
        }
    }

    &--orange {
        background-color: $palette-orange;
        border-bottom: 2px solid $palette-orange-dark;

        &:hover, &:active, &:focus {
            background-color: $palette-orange-light;
        }
    }

    &--complete {
        background-color: $palette-green;
        border-bottom: 2px solid $palette-green-dark;

        &:hover, &:active, &:focus {
            background-color: $palette-green-light;
        }
    }

    &--blue {
        background-color: $palette-blue;
        border-bottom: 2px solid $palette-blue-dark;

        &:hover, &:active, &:focus {
            background-color: $palette-blue-light;
        }
    }

    &--no-margin {
        margin-bottom: 0;
    }

    &:disabled {
        background: $palette-clouds;
        border-bottom: 2px solid $palette-silver;
        color: $palette-wet-asphalt;
        cursor: default;
    }
}
