.mini-footer {
    text-align: left;
    font-size: rem(14px);
    color: $palette-silver;
    position: relative;
    top: rem(-4px);

    .list {

        a {
            font-size: rem(14px);
            font-weight: normal;
        }

        .list-spacer {
            display: inline-block;
            margin-left: rem(10px);
            margin-right: rem(10px);
        }
    }
}
