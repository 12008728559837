.note-menu {
    display: block;
    margin-top: rem(8px);

    &__button-group {

    }

    &__button {
        padding: rem(16px);
        position: relative;
        border: 2px solid rgba($palette-blue, 0);
        border-radius: $handler-button-radius;
        transition: opacity $transition border $subtle-transition;

        &:before,
        .fa {
            line-height: 0;
            display: inline-block;
            position: absolute;
            top: rem(16px);
            left: rem(11px);
        }

        &:before {
            top: rem(8px);
            left: rem(13px);
        }

        &.dot {
            &:before {
                top: rem(10px);
            }
        }

        &.beam {
            &:before {
                top: rem(10px);
                left: rem(5px);
            }
        }

        &.tie {
            &:before {
                left: rem(8px);
            }
        }

        &:disabled {
            opacity: 0.2;
            cursor: default;
        }

        &[class*=" musisync-"] {
            font-size: rem(32px);
        }

        &.is-active {
            color: $palette-blue;
        }

        &.pulse-button {
          box-shadow: 0 0 0 0 $toast-warning;
          animation: pulse-button 1s infinite cubic-bezier(0.66, 0, 0, 1);
        }

        .fa {
            font-size: rem(14px);
        }
    }
}

@keyframes pulse-button {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
