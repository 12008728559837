.nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $nav-width;
    height: 100%;
    transform: translate3d(-$nav-width, 0, 0);
    z-index: $nav-z-index;
    // height: $navigation-height;
    background-color: $palette-wet-asphalt;
    color: $palette-clouds;
    transition: transform .25s cubic-bezier(.2,.3,.25,.9);

    @include bp-lte(sm) {
        .musie-app.open & {
            transform: translate3d(0px, 0, 0);
        }
    }

    &__container {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &::before, &::after {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            content: '';
            opacity: 0;
        }

        &::after {
            background: rgba(0,0,0,0.3);
            transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
            z-index: -1;
        }

        &__top {
            height: $navigation-height;

            // .logo {
            //     background-size: 70%;
            //     background-repeat: no-repeat;
            //     background-position: 50% 50%;
            //     display: block;
            //     height: 100%;
            //     position: relative;
            //     transition: opacity 0s;
            //
            //     @include bp-gte(lg) {
            //         transition: opacity 0.2s ease-out;
            //     }
            //
            //     &--white {
            //         opacity: 1;
            //         background-image: url('../svgs/notewhite.svg');
            //     }
            //
            //     &--blue {
            //         opacity: 0;
            //         background-image: url('../svgs/noteblue.svg');
            //     }
            // }
        }

        &-overlay {
            cursor: pointer;

            &::after {
                width: 100%;
                height: 100%;
                opacity: 1;
                transition: opacity 0.3s;
            }
        }

        &__list {
            height: 100%;
            margin: 0;
            padding: 0;
            list-style: none;

            &__item {
                display: list-item;

                &::before {
                    position: absolute;
                    left: 10px;
                    z-index: -1;
                    color: rgba(0,0,0,0.2);
                    line-height: 3.5;
                }

                .link {
                    display: block;
                    padding: 0.7em 1em 0.7em 1.8em;
                    outline: none;
                    box-shadow: inset 0 -1px rgba(0,0,0,0.2);
                    text-shadow: 0 0 1px rgba(255,255,255,0.1);
                    font-size: 1.1em;

                    &::before {
                        display: inline-block;
                        vertical-align:middle;
                        height:100%;
                        content: "";
                    }

                    .fa {
                        width: rem(15px);
                        text-align: center;
                    }

                    // &:hover, &:active, &:focus {
                    //
                    // }

                    .title-icon {
                        display: inline;

                        @include bp-gte(lg) {
                            display: none;
                        }
                    }

                    .item-name {
                        padding-left: 8px;
                    }
                }

                &:first-child .link {
                    box-shadow: inset 0 -1px rgba(0,0,0,0.2), inset 0 1px rgba(0,0,0,0.2);
                }
            }
        }
    }
}
