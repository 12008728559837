.main-bar {
    @extend .page-lr-pad--no-mob;
    margin-bottom: rem(16px);
    position: relative;
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    opacity: 1;

    &--transition-out {
        transform: translateY(80px);
        opacity: 0;
    }

    &--transition-in {
        transform: translateY(0);
        opacity: 1;
    }

    @include bp-lte(md) {
        width: 100%;
    }

    @include bp-gte(md) {
        padding: 0;
    }

    &__inner {
        min-height: 200px;

        @include bp-gte(md) {
            min-height: 600px;
        }
    }
}
