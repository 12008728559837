// Converts a pixel value to an fractional value. Accepts an optional base
// size in pixels if the container no longer has the default font-size (16px).
// Usage:
// .element {
//     font-size: frac(24px);
//
//     .nested-element {
//         font-size: frac(12px, 24px);
//     }
// }
@function frac($size, $base-size: $base-font-size) {
    @return ($size / $base-size);
}

// Converts a pixel value to an em value. Accepts an optional base size
// in pixels if the container no longer has the default font-size (16px).
// Usage:
// .element {
//     font-size: em(24px);
//
//     .nested-element {
//         font-size: em(12px, 24px);
//     }
// }
@function rem($size, $base-size: $base-font-size) {
    $frac: frac($size, $base-size);

    @if ($frac == 0) {
        @return 0;
    }

    @return $frac + 0rem;
}
