$search-results-pad: rem(8px);

.search-results {
    min-width: 400px;
    min-height: 40px;
    background: $palette-white;
    border-radius: $page-border-radius;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
    color: $palette-asbestos;
    text-align: left;
    position: absolute;
    display: none;
    z-index: 10000;
    top: rem(8px);
    right: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition-property: min-height, width, top, right;
    transition-property: min-height, width, top, right;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    overflow: hidden;

    li {
        display: block;
        line-height: 1;
    }

    &.is-open {
        display: block;
    }

    &__results {
        min-height: rem(100px);
    }

    &__results-group {

        &__header {
            padding: $search-results-pad;
            background-color: $palette-asbestos;
            color: $palette-white;
            display: block;

            h5 {
                margin-bottom: 0;
            }
        }

        &__results-list {

        }

        &__result {
            display: block;

            &:last-child .search-results__results-group__result__link {
                border-bottom: 0;
            }

            &__link {
                padding: rem(8px) 0;
                border-bottom: 1px solid $palette-asbestos;
                display: block;
            }

            &__pic-container {
                text-align: center;
            }

            &__pic {
                width: rem(40px);
                display: inline-block;
            }
        }
    }
}
