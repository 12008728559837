$topic-summary-badge-spacing-mobile: rem(46px);
$topic-summary-badge-spacing-desktop: rem(90px);

.topic-summary {
    background-color: $palette-white;
    padding-top: 3rem;
    position: relative;

    &__title {
        &__page {
            padding-bottom: 60px;
        }

        h3 {
            font-weight: normal;
        }

        &.hide-desktop {

            .page {
                padding-left: rem(10px);
                padding-right: rem(10px);
            }

            .topic-summary__title {
                &__inner {
                    position: relative;
                    padding-left: 6rem;

                    @include bp-gte(lg) {
                        padding-left: 7rem
                    }

                    h2 {
                        margin: 0;
                    }
                }

                &__badge {
                    display: inline-block;
                    width: $topic-summary-badge-spacing-mobile;
                    height: rem(46px);
                    vertical-align: top;
                    position: absolute;
                    top: 0;
                    left: rem(32px);

                    @include bp-gte(md) {
                        width: $topic-summary-badge-spacing-desktop;
                        height: rem(68px);
                        margin-right: rem(25px);
                        left: 0;
                    }
                }
            }
        }

        &.hide-mobile-tablet {

            h2 {
                margin: 0 0 0.1em;
            }

            .topic-summary__title {
                &__badge {
                    max-height: rem(255px);
                }

                &__desc {
                    min-height: rem(100px);
                }
            }
        }

        &__button {
            background-color: $palette-green;
            font-size: rem(16px);
            width: auto;
            padding: rem(12px) rem(32px);

            &:hover,
            &:active,
            &:focus {
                background-color: darken($palette-green, 10%);
            }
        }
    }

    &__structure {
        background-color: $palette-wet-asphalt;

        .page {
            padding-top: rem(20px);
            padding-bottom: rem(20px);
        }

        &__list {
            position: relative;
            top: rem(-78px);

            @include bp-gte(lg) {
                top: rem(-88px);
            }
        }

        &__item {
            display: block;
            padding: 0 rem(8px);

            @include bp-gte(md) {
                padding: 0;
            }

            &:last-child .topic-summary__structure__link {
                margin-bottom: 0;
            }
        }

        &__link {
            display: block;
            font-size: rem(18px);
            background-color: $palette-white;
            margin-bottom: rem(24px);
            box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
            height: auto;
            color: $palette-asbestos;
            text-align: left;
            position:relative;
            transition: background-color $transition;
            padding: rem(24px);

            @include bp-gte(md) {
                padding: 2.1875rem 2.5rem 2.5rem 2.5rem;
            }

            &:focus:not(:active),
            &:hover,
            &:active,
            &:focus{
                background-color: $palette-hover;
            }

            &__text {
                display: block;
                padding: rem(20px) rem(20px) rem(20px) rem(60px);
                line-height: 1;
                position: relative;

                .fa {
                    position: absolute;
                    font-size: rem(40px);
                    left: 0;
                    top: 0;
                    line-height: rem(58px);
                }
            }

            &__type {
                text-transform: uppercase;
                font-weight: bold;
            }

            &__name {
                text-transform: none;
            }
        }
    }
}
