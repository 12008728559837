.piano {
    display: inline-flex;
    background-color: $palette-wet-asphalt;
    padding-left: rem(2px);
    padding-right: rem(2px);
    transition: opacity $transition;

    &--120 {
        height: rem(120px);

        .piano__key {
            &--white {
                height: 50%;
                margin: rem(60px) rem(1px) 0;
            }

            &--black {
                height: calc(50% - #{rem(1px)});
                margin: 0 rem(-10px);
            }
        }

        .piano__key-top {
            height: rem(60px);
        }
    }

    &--160 {
        height: rem(160px);

        .piano__key {
            &--white {
                height: 50%;
                margin: rem(80px) rem(2px) 0;
            }

            &--black {
                height: calc(50% - #{rem(2px)});
            }
        }

        .piano__key-top {
            height: rem(80px);
        }
    }

    &__key {
        position: relative;
        transform: translateY(0);
        transition: background-color $subtle-transition;
        border-radius: 0 0 rem(2px) rem(2px);
        font-size: rem(10px);
        text-align: center;

        &.is-active {
            background-color: $palette-blue;
        }

        &--white {
            background-color: $palette-white;
            width: rem(40px);
            color: $palette-wet-asphalt;
        }

        &--black {
            height: rem(78px);
            width: rem(20px);
            background-color: black;
            color: $palette-white;

            .piano__key__name {
                font-size: rem(8px);
                top: rem(34px);
            }
        }

        &__name {
            position: relative;
            top: rem(30px);
        }
    }

    &__key-top {
        position: absolute;
        bottom: 100%;
        border: inherit;
        height: rem(80px);
        background-color: inherit;
        border-bottom: 0;
        width: rem(30px);

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }

        &--center {
            left: rem(10px);
            width: rem(20px);
        }
    }
}
