.exercise-ask-area {
    @extend .no-select;
    background-color: $palette-clouds;
    height: rem(200px);
    position: relative;
    overflow: hidden;
    border-bottom-left-radius: $page-border-radius;
    border-bottom-right-radius: $page-border-radius;

    @include bp-gte(md) {
        height: 105px;
    }

    &__desc {
        min-height: rem(100px);


        @include bp-gte(md) {
            height: 100%;
            min-height: initial;
        }

        &__text {
            padding: rem(20px);
        }
    }

    &__check {
        height: rem(80px);
        position: relative;
        right: -1px;

        @include bp-gte(md) {
            height: 100%;
        }
    }

    &__qNum {
        font-weight: bold;
    }

    &__button {
        width: 100%;
        display: block;
        color: $palette-white;
        background-color: $palette-green;
        height: 100%;

        &:hover,
        &:active,
        &:focus {
            background-color: rgb(62, 182, 113);
        }

        &:disabled {
            background-color: $palette-asbestos;
            color: $palette-silver;
            cursor: default;
        }
    }
}
