.multi-choice-text {

    &__question-target {
        height: rem(360px) - rem(20px);
        background-color: $palette-clouds;
        /* padding: 86px; */
        border-radius: $page-border-radius;
        margin: rem(10px) rem(20px);
        padding: rem(12px);
        text-align: center;

        &__middle {

        }

        p {
            font-size: rem(40px);
            margin-bottom: 0;
        }
    }

    &__options {
        padding: 0 rem(30px);
    }

    &__option {
        padding: rem(8px);
        display: block;

        &__label {
            display: block;
            cursor: pointer;
            border: 4px solid;
            border-radius: rem(8px);
            border-color: $palette-asbestos;
            padding: rem(12px) rem(24px);
            transition: border-color $transition;

            &.mdl-radio.is-upgraded {
                padding-left: rem(40px);
            }

            &.is-checked {
                border-color: $palette-blue;
            }

            p {
                display: inline;
            }

            img {
                width: rem(20px);
                display: inline;
            }

            .mdl-radio {
                &__outer-circle {
                    top: rem(16px);
                    left: rem(13px);
                }

                &__inner-circle {
                    top: rem(21px);
                    left: rem(18px);

                    @include bp-gte(md) {
                        top: rem(20px);
                        left: rem(17px);
                    }
                }

                &__ripple-container {
                    top: 0px;
                    left: -4px;

                    @include bp-gte(md) {
                        top: rem(3px);
                        left: rem(0px);
                    }
                }
            }
        }

        &.is-incorrect-answer label {
            border-color: $palette-red;
        }

        &.is-correct-answer label {
            border-color: $palette-green;
        }
    }

    &__answer {
        padding: rem(12px);
        color: $palette-green;
        text-align: center;
    }
}
