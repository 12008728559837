.our-goals-page {

    &__page {

        @include bp-gte(md) {
            padding-top: 6.25rem;
        }
    }

    &__tabs {
        position: relative;
        display: block;
        line-height: 1;
        padding: rem(24px);
        text-align: center;
    }

    &__tab {

        &__link {
            &.link {
                font-size: rem(16px);
                background-color: $palette-clouds;
                color: $palette-asbestos;
                transition: background-color $transition, color $transition;
                border-radius: $page-border-radius;
                padding: 12px 16px;


                &.is-active {
                    background-color: $palette-blue-dark;
                    color: white;
                }

                &:hover,
                &:active,
                &:focus {
                    background-color: $palette-blue-dark;
                    color: white;
                }
            }
        }
    }

    &__tab-content {
        padding: 0 rem(24px);
        min-height: rem(600px);
        position: relative;
        margin-bottom: rem(32px);
        padding-top: rem(24px);

        &__markdown {
            ol, ul {
                display: block;
                list-style: circle;
                padding-left: rem(24px);
                margin-bottom: rem(24px);
            }

            li {
                display: list-item;
                list-style: circle;
                font-size: 1rem;
                line-height: 1.4;
                letter-spacing: 1px;
                margin-bottom: rem(8px);
                font-weight: normal;

                @include bp-gte(lg) {
                    margin: 0 0 rem(16px);
                }
            }

            ol {
                list-style: decimal;

                li {
                    list-style: decimal;
                }
            }
        }
    }

    &__page-item {
        padding: 0;
        overflow: hidden;
    }

    &__the-product {

        &__topic-item {
            position: absolute;
            font-size: rem(24px);
        }

        &__topic-item-text {
            padding-left: rem(140px);
        }
    }
}
