.mdl-textfield {
    width: 100%;
}

.validation-error {
    color: $palette-red;
    text-align: left;
    display: block;
    position: relative;
    top: rem(-20px);
}

.feedback {
    text-align: center;
    max-width: rem(350px);
    margin: 0 auto;
    position: relative;

    form {
        z-index: 1;
    }

    &__input {
        display: block;
        line-height: rem(24px);
    }

    .loading {
        display: block;
        height: 100%;
        background-image: url('../svgs/loading.svg');
        background-position: 50% 20%;
        background-size: 60%;
        background-repeat: no-repeat;
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        &.hide {
            display: none;
        }
    }

    .is-required {
        color: $palette-red;
        padding-right: rem(16px);
        position: relative;
        text-align: right;
        display: block;
        font-size: 0.8em;
        margin-top: 8px;

        &:before {
            width: rem(6px);
            height: rem(6px);
            opacity: 0.8;
            background-color: $palette-red;
            border-radius: 50%;
            top: 0;
            right: 2px;
            content: "";
            position: absolute;
        }
    }
}
