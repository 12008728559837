.purchase-page {

    &__title-block {
        margin-bottom: rem(32px);

        h2 {
            margin-bottom: rem(8px);
        }
    }

    &__group {
        margin-bottom: rem(8px);
        position: relative;

        &__title {
            margin-bottom: rem(4px);
        }
    }

    &__input {

        &--pad {
            padding-right: rem(12px);
        }
    }

    &__details {
        position: relative;
        padding-right: rem(24px);

        .mdl-spinner {
            width: rem(60px);
            height: rem(60px);
        }

        &__address {

        }
    }

    &__submit {
        margin-top: rem(12px);
        margin-bottom: rem(12px);
    }

    &__cart {
        border: 2px solid $palette-silver;
        padding: rem(16px) rem(24px);
        border-radius: $page-border-radius;

        &__item {
            border-top: 1px solid $palette-clouds;
            padding: rem(8px) 0;
            display: block;
            text-transform: capitalize;

            &:last-child {
                border-bottom: 1px solid $palette-clouds;;
            }
        }

        &__total {
            margin-top: rem(12px);
        }
    }

    &__checkout {
        position: relative;
    }

    &__checkout-form {

    }

    &__checkout-result {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        background-color: $palette-white;
        z-index: $page-item-z-index;
        text-align: center;

        &--complete {
            color: $palette-green;
        }

        &--error {
            color: $palette-red;
        }

        &__icon {
            font-size: rem(60px);
            margin-bottom: rem(8px);
        }

        &__message {

        }
    }
}
