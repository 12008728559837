.user-question {
    font-size: rem(18px);

    &__toolbar {
        text-align: center;
        text-transform: capitalize;
        margin-bottom: rem(16px);
        display : none;

        .page__item {
            padding: 12px;
        }
    }

    &__item-bar {
        visibility: hidden;
        height: 20px;
        opacity: 0;
        display: none;
    }

    &__page {
        padding: 0;
        margin-top: rem(12px);
    }

    .score-ui {
        margin-bottom: 0;
    }

    .answer-area {
        padding: rem(42px);
    }

    h2 {
        margin-bottom: 0;
    }

    .quiz-complete {
        padding: rem(32px);
        
        h2 {
            margin-bottom: rem(12px);
        }
    }
}
