$page-max-width: rem(1160px);
$page-min-width: rem(320px);
$page-thin-max-width: rem(960px);
$page-border-radius: rem(4px);

$heading-sizes:                 3rem, 2.25rem, 1.5rem, 1.3125rem, 1.125rem, 1rem;
$heading-sizes-mobile:          2rem, 1.5rem, 1.31rem, 1.125rem, 1rem, 1rem;
$heading-line-heights:          1.2, 1.2, 1.2, 1.2, 1.2, 1.2;
$heading-margins:               1rem, 0.8rem, 0.8rem, 0.8rem, 0.4rem, 0.4rem;
$heading-margins-mobile:        0.8rem, 0.8rem, 0.8rem, 0.8rem, 0.4rem, 0.4rem;

$navigation-height: rem(55px);
$navigation-mob-height: rem(65px);
$overlay-modal: 24;
$nav-z-index: 20;
$piano-z-index: 17;
$header-z-index: 10;
$page-item-z-index: 9;
$overlay-z-index: 14;
$card-z-index: 18;

$nav-width: rem(230px);

@import 'components/page';
@import 'components/text';

@import 'components/navigation';
@import 'components/header';
@import 'components/sidebar';
@import 'components/modal';
@import 'components/page-item';
// @import 'components/grid-links';
@import 'components/notification';
@import 'components/buttons';
@import 'components/sub-nav';
@import 'components/spinner';
@import 'components/upsell-banner';
@import 'components/card';
@import 'components/profile-pic';
@import 'components/info-icon';
@import 'components/something-wrong';
@import 'components/stripe';
@import 'components/purchase-banner';

@import 'components/error-message';

// @import 'components/homepage';
@import 'components/progresspage';
@import 'components/intropage';
@import 'components/about-page';
@import 'components/meet-the-team-page';
@import 'components/our-goals-page';
@import 'components/purchase-page';
@import 'components/design-page';
@import 'components/contact';
@import 'components/publish';
@import 'components/newsletter';

@import 'components/progress-user-questions';
@import 'components/compose/compose-question';

@import 'components/bottom-banner';
@import 'components/banner-top';
@import 'components/main-promo.scss';
@import 'components/list-item-user.scss';

@import 'components/form/inputs';
@import 'components/form/response';
@import 'components/form/feedback';
@import 'components/form/publish-form';
@import 'components/form/preview-lesson';

// @import 'components/discover';

// @import 'components/courses';
// @import 'components/course';

@import 'components/topic/topic';
@import 'components/topic/topic-toolbar';
@import 'components/topic/topic-item';
@import 'components/topic/topic-progress';
@import 'components/topic/topic-item-bar';
@import 'components/topic/move-to-topic-item';
@import 'components/topic/move-to-next-unit';
@import 'components/topic/topic-summary';
@import 'components/topic/topic-review';

@import 'components/quiz/quiz';
@import 'components/quiz/quiz-complete';
@import 'components/quiz/quiz-tip';

@import 'components/lesson/lesson-page';
@import 'components/lesson/lesson-content';

@import 'components/exercise/exercise-page';
@import 'components/exercise/exercise-content';
@import 'components/exercise/exercise-ask-area';

@import 'components/answer-area';

@import 'components/quiz/answer-area/multi-choice';
@import 'components/quiz/answer-area/multi-choice-text';
@import 'components/quiz/answer-area/multi-choice-image';
@import 'components/quiz/answer-area/stave-text-input';

@import 'components/loading';
@import 'components/title';
@import 'components/footer';
@import 'components/mini-footer';

@import 'components/player-module/player-module';
@import 'components/player-module/player-module-toggle';
@import 'components/player';

@import 'components/promo-section';

@import 'components/user/profile';
@import 'components/user/profile-images';
@import 'components/user/user-header';
@import 'components/user/user-dropdown';
@import 'components/user/connect-button';
@import 'components/user/invite-form';

@import 'components/handler/handler';
@import 'components/handler/handler-toolbar';
@import 'components/handler/handler-note-menu';
@import 'components/handler/score-ui';

@import 'components/vextab-player';

@import 'components/piano/piano-container';
@import 'components/piano/piano';

@import 'components/search/search-toggle';
@import 'components/search/search-results';

@import 'components/user-question';

.musie-app {
    position: relative;
    left: 0;
    height: 100%;
    overflow-x: hidden;

    &__page {
        transition: transform .25s cubic-bezier(.2,.3,.25,.9);
        min-height: 100vh;

        &__overlay {
            display: block;
            transition: background-color .15s ease-out 0s;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: rgb(0,0,0);
            opacity: 0;
            z-index: $overlay-z-index;
            visibility: hidden;
            transition: 0.3s opacity;
        }
    }

    &.open .musie-app__page {
        @include bp-lte(sm) {
            transform: translate3d($nav-width, 0, 0);

            &__overlay {
                background-color: rgb(0,0,0);
                opacity: 0.3;
                visibility: visible;
            }
        }
    }
}

.toast-top-right {
    top: rem(63px)!important;
}
