$page-item-padding: rem(35px) rem(40px) rem(40px) rem(40px);

* {
    box-sizing: border-box;
}

html, body {
    background-color: $palette-page;
    // font-family: "Helvetica Neue",Helvetica,sans-serif;
    font-family: "Open-Sans", "Helvetica Neue", Helvetica, sans-serif;
    font-size: $mobile-font-size;
    color: $palette-asbestos;
    min-width: $page-min-width;
    height: 100%;
    position: relative;

    @include bp-gte(md) {
        font-size: $base-font-size;
    }
}

header {
    min-width: $page-min-width;
}

ol {
    line-height: 0;
}

.inline {
    display: inline!important;
}

.roundedtext {
    font-family: 'Varela Round', sans-serif;
}

#app {
    position: relative;
    width: 100%;
    display: block;
    z-index: 2;
}

.paper {
    border-radius: $page-border-radius;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
}

.page-component {

    &--padding-bottom {
        padding-bottom: rem(80px);
    }
}

.page {
    margin: 0 auto;
    max-width: $page-max-width;

    .no-touch & {

        @include bp-gte(lg) {
            width: $page-max-width;
        }
    }

    @include bp-gte(md) {
        padding: 0 rem(16px);
    }

    &--padded {
        padding-top: $navigation-mob-height;
        padding-bottom: 0;

        @include bp-gte(md) {
            padding-top: rem(25px) + $navigation-height;
            padding-bottom: rem(25px);
        }
    }

    &--padded-no-mob {
        padding-top: $navigation-mob-height;

        @include bp-gte(md) {
            padding-top: rem(25px) + $navigation-height;
            padding-bottom: 5.125rem;
        }

        @include bp-gte(lg) {
            padding-top: rem(45px+55px);
        }
    }

    &--padded-with-toolbar-no-mob {
        padding-top: $navigation-mob-height;
        padding-bottom: 7.125rem;

        @include bp-gte(md) {
            padding-top: rem(13px) + $navigation-height;
        }
    }

    &--padded-with-toolbar {
        padding-top: $navigation-mob-height;
        padding-bottom: 7.125rem;

        @include bp-gte(md) {
            padding-top: rem(13px) + $navigation-height;
        }
    }

    &--white {
        background-color: $palette-white;
    }

    &__item {
        background-color: $palette-white;
        border-radius: 0;
        position: relative;
        padding: 0;
        margin: 0;

        &--padded {
            padding: 0 rem(16px);
        }

        @include bp-gte(md) {
            // @extend .paper;
            border-radius: $page-border-radius;
            box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
            padding: $page-item-padding;
        }
    }
}

.page-thin {
    @extend .page;
    max-width: $page-thin-max-width;
}

.page-no-padding {
    @extend .page;
    max-width: $page-max-width;
}

.page-top-pad {
    padding-top: rem(56px);
}

.page-lr-pad {
    padding-left: rem(10px);
    padding-right: rem(10px);

    @include bp-gte(md) {
        padding-left: rem(16px);
        padding-right: rem(16px);
    }
}

.page-lr-pad--no-mob {
    padding-left: rem(0px);
    padding-right: rem(0px);

    @include bp-gte(md) {
        padding-left: rem(16px);
        padding-right: rem(16px);
    }
}

.page-load {
    z-index: 4;
    background-color: $palette-off-white;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    opacity: 1;
    transition: opacity 0.3s linear 0.5s;

    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    &__logo {
        margin-bottom: rem(16px);
        width: rem(160px);
        height: rem(160px);
    }

    .spinner {
        width: rem(200px);
        text-align: center;
        display: inline-block;

        &__item {
            width: rem(16px);
            height: rem(16px);
            background-color: #fff;
            border-radius: 100%;
            display: inline-block;
            animation: sk-bouncedelay 1.7s infinite ease-in-out both;
            margin-right: rem(5px);
        }

        &__item--1 {
            animation-delay: -0.60s;
            background-color: $palette-blue;
        }

        &__item--2 {
            animation-delay: -0.40s;
            background-color: $palette-yellow;
        }

        &__item--3 {
            animation-delay: -0.20s;
            background-color: $palette-green;
        }

        &__item--4 {
            background-color: $palette-red;
        }
    }
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0;
  } 40% {
    transform: scale(1.0);
    opacity: 1;
  }
}

.wrapper {
    background-color: $palette-off-white;
    width: 100%;
    min-height: rem(200px);

    // @include bp-gte(md) {
    //     background-color: $palette-off-white;
    // }
}

.content {
    padding: rem(55px) 0;

    &--with-sub {
        padding: 0;
    }
}

.logo {
    background-image: url('../svgs/noteblue.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img {
    margin: 0 auto;
}

@for $i from 1 through 6 {
    h#{$i} {
        font-size: nth($heading-sizes-mobile, $i);
        line-height: nth($heading-line-heights, $i);
        margin: 0 0 nth($heading-margins-mobile, $i) 0;
        font-family: 'Nunito';
        font-weight: bold;

        @include bp-gte(md) {
            margin: 0 0 nth($heading-margins, $i) 0;
            font-size: nth($heading-sizes, $i);
        }
    }
}


h2 {
    margin: 0 0 0.6em;
}

p {
    font-size: rem(15px);
    line-height: 1.4;
    letter-spacing: 1px;
    margin: 0 0 rem(12px);
    font-weight: normal;

    @include bp-gte(lg) {
        margin: 0 0 rem(16px);
    }
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.link {
    color: $palette-blue;
    transition: color $transition;

    &:hover, &:active, &:focus {
        color: lighten($palette-blue, 10%);
    }

    &--dark {
        color: $palette-off-white;

        &:hover, &:active, &:focus {
            color: lighten($palette-blue, 10%);
        }
    }

    &--sign-in {
        color: #ee836e;

        &:hover, &:active, &:focus {
            color: lighten(#ee836e, 10%);
        }
    }
}

.link-hover {

    &:focus:not(:active),
    &:hover,
    &:active,
    &:focus{
        background-color: $palette-hover;
    }
}

.g-pad {
    padding-right: rem(35px);

    &:last-child {
        padding: 0;
    }
}

.g-pad-sm {
    padding-right: 0;

    @include bp-gte(md) {
        padding-right: rem(35px);

        &:last-child {
            padding: 0;
        }
    }
}

.share-button {
    position: relative;
    padding: rem(4px) rem(8px);
    font-weight: 500;
    display: inline-block;
    color: $palette-white;
    cursor: pointer;
    border-radius: 3px;
    text-align: center;
    margin-bottom: rem(12px);

    &--twitter {
        background-color: $twitter;

        &:hover {
            background-color: #0c7abf;
        }
    }

    &--facebook {
        background-color: $facebook;

        &:hover {
            background-color: darken($facebook, 10%);
        }
    }

    i {
        width: 16px;
    }

    span {
        margin-left: rem(5px);
    }
}

.star {
    color: $palette-yellow;
}

.verifier {
    color: $palette-red;
}

.profileName {
    color: $palette-blue;
}

.overlay {
    display: block;
    transition: background-color .15s ease-out 0s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(0,0,0);
    opacity: 0;
    z-index: $overlay-z-index;
    visibility: hidden;
    transition: 0.3s opacity;

    &.open {
        background-color: rgb(0,0,0);
        opacity: 0.3;
        visibility: visible;
    }
}

.correct {
    color: $palette-green;
}

.times {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-style: italic;
}

.italic {
    font-style: italic;
}

.legal-text {
    font-size: rem(13px);
    font-style: italic;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.abs {
    position: absolute;
}

.bot {
    bottom: 0;
}
