.invite-form {
    position: relative;

    &--sent,
    &--error {
        padding: rem(6px) rem(12px);
        text-align: center;
        border-top: 1px solid rgba($palette-blue, 0.5);

        span {
            display: inline-block;
            margin-bottom: 0;
            width: 50%;
            text-align: center;
        }

        button {
            text-align: center;
            color: rgba($palette-blue, 0.5);
            display: inline-block;
            border: 1px solid rgba($palette-blue, 0.5);
            padding: rem(4px);
            overflow: hidden;
            border-radius: $page-border-radius;
            z-index: 2;
            transition: background-color $transition color $transition;

            &:focus,
            &:active,
            &:hover {
                color: $palette-white;
                background-color: rgba($palette-blue, 0.5);
                border: 1px solid transparent;
            }

        }
    }

    .form {
        width: 100%;

        &--valid {

        }

        &--invalid {

            .input {
                color: $palette-red;
            }
        }
    }

    .input {
        display: block;
        border: 0;
        padding: rem(12px);
        width: 100%;
        border-top: 1px solid rgba($palette-blue, 0.5);
        color: $palette-blue;

        &:focus {
            outline: 0;
        }
    }

    .submit {
        position: absolute;
        right: rem(8px);
        top: rem(8px);
        color: rgba($palette-blue, 0.5);
        display: inline-block;
        border: 1px solid rgba($palette-blue, 0.5);
        padding: rem(4px);
        overflow: hidden;
        border-radius: $page-border-radius;
        z-index: 2;
        transition: background-color $transition color $transition;

        &:focus,
        &:active,
        &:hover {
            color: $palette-white;
            background-color: rgba($palette-blue, 0.5);
            border: 1px solid transparent;
        }
    }
}
