/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
    display: inline-block;
    position: relative;
    background-color: $palette-white;
    padding: rem(8px) rem(12px);
    border-radius: 4px;
    border: 1px solid $palette-clouds;
    box-shadow: 0 0 0 0 $palette-clouds;
    transition: box-shadow $transition, border $transition;
    margin-bottom: rem(8px);
    width: 100%;

    &::placeholder {
        color: #CFD7DF;
    }

    &--focus,
    &:focus {
        box-shadow: 0 1px 3px 0 $palette-silver;
        outline: none;
        border-color: transparent;
    }

    &--select {
        background-color: inherit;
        padding: inherit;
        border-radius: inherit;
        border: inherit;
        margin: 0!important;

        .Select {
            // &:focus,
            // &:active {
            //     box-shadow: 0 1px 3px 0 $palette-silver!important;
            //     outline: none!important;
            //     border-color: transparent!important;
            // }
        }

        .Select-control {
            border: 1px solid $palette-clouds;
            height: 42px!important;

            .Select-placeholder {
                color: #CFD7DF;
                line-height: 42px!important;
            }

            .Select-value {
                line-height: 42px!important;
            }

            .Select-input {
                line-height: 42px!important;
            }
        }
    }

    &--invalid {
        border-color: $palette-red;

        &:focus {
            border-color: $palette-red;
        }
    }

    &--webkit-autofill {
        background-color: #fefde5 !important;
    }
}

.is-focused:not(.is-open) > .Select-control {
    box-shadow: 0 1px 3px 0 $palette-silver!important;
    outline: none!important;
    border-color: transparent!important;
}
