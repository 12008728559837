.promo-section {
    background: $palette-blue;
    background: linear-gradient(to top, $palette-blue-light, $palette-blue-dark);
    color: $palette-white;
    overflow: hidden;
    position: relative;
    height: rem(200px);

    &__page {
        padding-top: rem(40px) + $navigation-height;
        padding-bottom: rem(25px);

        @include bp-gte(md) {
            padding-top: rem(105px);
        }
    }

    &__cloud {
        height: auto;
        display: inline-block;
        position: absolute;
        right: 0;
        top: rem(75px);
        opacity: 0.1;

        &--one {
            width: rem(100px);
            right: rem(40px);
            top: rem(20px);

            @include bp-gte(lg) {
                top: 20%;
            }
        }

        &--two {
            width: rem(200px);
            top: rem(40px);
            right: rem(300px);

            @include bp-gte(lg) {
                top: 10%;
            }
        }

        @include bp-gte(lg) {
            opacity: 1;
        }
    }

    &__logo {
        height: rem(200px);
        width: rem(200px);
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.1;

        @include bp-gte(lg) {
            opacity: 1;
        }
    }

    &__container2 {
        z-index: 1;
    }

    &__container {
        z-index: 2;
        position: relative;
        height: 100%;
        text-align: left;
        padding-left: rem(40px);
        padding-top: rem(12px);

        h2 {
            margin-bottom: 0;
        }
    }

    p {
        font-size: rem(18px);
    }

    .cursor {
        margin-left: rem(-2px);
        animation: 1s blink infinite;
    }

    .tagline {
        min-height: rem(110px);

        @include bp-gte(md) {
            margin-bottom: rem(44px);
        }
    }

    @keyframes "blink" {
        from, to {
          opacity: 1;
        }
        15% {
          opacity: 1;
        }
        85% {
          opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }

    &__container {

        &__button {
            text-align: left;
            padding: rem(16px);
            width: auto;
        }
    }
}
