.multi-choice-image {

    &__options {
        padding: rem(12px) rem(30px) 0 rem(30px);
        text-align: center;
    }

    &__option {
        padding: rem(8px);

        &__image {
            height: 99px;
            margin-top: -15px;
        }

        &__label {
            max-width: rem(486px);
            margin: 0 auto;
            text-align: left;
            min-height: rem(150px);
            display: block;
            cursor: pointer;
            border: 4px solid;
            border-radius: rem(8px);
            border-color: $palette-asbestos;
            padding: rem(12px) rem(24px);
            transition: border-color $transition;

            &.mdl-radio.is-upgraded {
                padding-left: rem(40px);
            }

            &.is-checked {
                border-color: $palette-blue;
            }

            .mdl-radio {
                &__outer-circle {
                    top: rem(16px);
                    left: rem(13px);
                }

                &__inner-circle {
                    top: rem(21px);
                    left: rem(18px);

                    @include bp-gte(md) {
                        top: rem(20px);
                        left: rem(17px);
                    }
                }

                &__ripple-container {
                    top: 0px;
                    left: -4px;

                    @include bp-gte(md) {
                        top: rem(3px);
                        left: rem(0px);
                    }
                }
            }
        }

        &.is-incorrect-answer label {
            border-color: $palette-red;
        }

        &.is-correct-answer label {
            border-color: $palette-green;
        }
    }

    &__answer {
        padding: rem(12px);
        color: $palette-green;
        display: block;
        text-align: center;
    }
}
