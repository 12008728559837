$question-item-height: rem(80px);

.progress-user-questions {

    &__compose-button {
        display: block;
        width: 100%;
        height: 60px;
        line-height: 0;
        // background-color: $palette-red;
        // color: $palette-white;
        // border-radius: $page-border-radius;

        .fa {
            margin-right: rem(4px);
        }
    }

    &__questions-list {
        display: block;
        padding: rem(24px) 0;
        line-height: 1;
    }

    &__question-item {
        display: block;

        &__link {
            display: block;
            border-radius: $page-border-radius;
            background-color: $palette-white;
            border: 1px solid $palette-silver;
            padding: rem(12px);
            margin-bottom: rem(12px);
            position: relative;
            min-height: $question-item-height;

            &:hover,
            &:active,
            &:focus {
                background-color: $palette-hover;
            }
        }

        &__profile {
            position: absolute;
            left: rem(14px);
            top: rem(14px);
            white-space: nowrap;
            overflow: hidden;

            .profile-pic {
                height: $question-item-height - rem(30px);
                width: $question-item-height - rem(30px);
                margin-bottom: rem(6px);
            }
        }

        &__question {
            margin-left: $question-item-height;
            padding: 0 rem(12px);
            min-height: rem(70px);

            h5 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            p {
                margin-bottom: rem(8px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__details {
            display: block;
            line-height: 1;
            white-space: nowrap;
            overflow: hidden;
        }

        &__detail-item {
            display: inline-block;
            padding: 0 rem(8px);
            border-right: 1px solid $palette-silver;
            text-transform: capitalize;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }
    }
}
