.spinner {
    text-align: center;
    padding: rem(20px);
    line-height: 1;
    position: relative;
    height: 100%;

    &--no-pad {
        padding: 0;
    }
}
