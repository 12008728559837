.sidebar {

    @include bp-lte(md) {
        width: 100%;
    }

    @include bp-gte(lg) {
        margin-bottom: rem(16px);
    }

    &__item {
        padding: 0;
        margin-right: 0;
        margin-bottom: rem(18px);
        // overflow: hidden;

        @include bp-lte(md) {
            &.page__item {
                margin-top: 0;
            }
        }

        @include bp-gte(lg) {
            margin-right: rem(24px);
        }
    }
}
