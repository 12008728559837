.notification {
    z-index: 11;
    width: 400px;
    border: 1px solid rgba($palette-asbestos, 0.4);
    background-color: $palette-orange-light;
    border-radius: 4px;
    opacity: 0.96;
    position: fixed;
    top: 20px;
    right: 120px;
    overflow: hidden;
    color: $palette-asbestos;

    &__content {
        position:relative;
        padding: 22px;
        height: 100%;

        &__close {
            position:absolute;
            right: 0;
            top: 0;
            padding: 12px;

            i {
                vertical-align: top;
                line-height: 13px;
            }

            &:hover {
                color: darken($palette-asbestos, 20%);
            }
        }

        &__message {

        }
    }

    &__close {


    }
}
