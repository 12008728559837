.footer {
    // height: 400px;
    padding: rem(60px) 0;
    background-color: darken($palette-clouds, 2%);
    // color: $palette-clouds;
    border-top: 1px solid rgba($palette-silver, 0.2);
    font-size: 0.6;
    text-align: center;
    font-size: rem(16px);

    &__social {
        font-size: rem(32px);

        &__link {
            margin-right: rem(8px);
        }
    }

    .list {
        margin-bottom: rem(20px);

        .list-spacer {
            padding-left: rem(10px);
            padding-right: rem(10px);
        }
    }

    .notedark {
        background-image: url('../svgs/notedark.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        width: rem(30px);
        height: rem(30px);
        display: block;
        margin: 0 auto;
    }

    .noteabltext {
        background-image: url('../svgs/noteabltext.svg');
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: rem(200px);
        height: rem(80px);
        display: block;
        margin: rem(16px) auto rem(32px);
    }
}
