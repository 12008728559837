.flex-container {
  list-style: none;
  display: block;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  // justify-content: space-between;
}

.flex-item {
  width: 200px;
}
