.mdl-textfield {
    width: 100%;
}

.Select {
    margin: rem(16px) 0;
}

.Select-menu-outer {
  z-index: 999 !important;
}

.validation-error {
    color: $palette-red;
    text-align: left;
    display: block;
    position: relative;
    top: rem(-20px);
}

.publish-form {
    text-align: center;
    position: relative;

    form {
        z-index: 1;
    }

    &__published {
         margin-top: 18px;

        &__item {
            display: block;
            min-height: 20px;
        }

        &__button {
            border: 3px solid $palette-red;
            background-color: $palette-clouds;
            border-radius: 4px;
            margin-bottom: 8px;
            line-height: 1;
            padding: 12px;
            width: 100%;
        }
    }

    &__group {
        margin-bottom: rem(48px);
    }

    &__input {
        display: block;
        line-height: rem(24px);
        float: left;
        padding: 0 rem(35px);
    }

    &__structure-items {
        display: block;
        line-height: 1;
        padding: 20px;
        border: 1px solid $palette-asbestos;
        background-color: $palette-clouds;
        border-radius: 4px;
        margin-bottom: rem(24px);
    }

    &__structure-item {
        display: block;
        padding: 12px;
        border: 1px solid $palette-asbestos;
        border-radius: 4px;
        margin-bottom: rem(24px);
        position: relative;
        padding-top: rem(32px);

        &--lesson {
            background-color: $palette-yellow;
        }

        &--exercise {
            background-color: $palette-red;
        }

        &--quiz {
            background-color: $palette-emerald-dark;
        }

        &__select {
            margin-bottom: rem(24px);
        }

        &__slide {
            display: block;
            padding: 12px;
            border: 1px solid $palette-asbestos;
            border-radius: 4px;
            margin-bottom: rem(24px);
            background-color: $palette-clouds;
        }

        &__delete {
            display: inline-block;
            position: absolute;
            float: right;
            top: 0;
            right: 0;
            padding: rem(14px);
        }
    }

    .loading {
        display: block;
        height: 100%;
        background-image: url('../svgs/loading.svg');
        background-position: 50% 20%;
        background-size: 60%;
        background-repeat: no-repeat;
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        &.hide {
            display: none;
        }
    }

    .is-required {
        color: $palette-red;
        padding-right: rem(16px);
        position: relative;
        text-align: right;
        display: block;
        font-size: 0.8em;
        margin-top: 8px;

        &:before {
            width: rem(6px);
            height: rem(6px);
            opacity: 0.8;
            background-color: $palette-red;
            border-radius: 50%;
            top: 0;
            right: 2px;
            content: "";
            position: absolute;
        }
    }
}
