.topic-review {

    h2 {
        margin-bottom: rem(12px);
    }

    h1, h3 {
        text-align: center;
    }

    h5 {
        .spinner {
            display: inline-block;
        }
    }

    &__page {
        padding: rem(32px) rem(24px);

        @include bp-gte(md) {
            padding: rem(40px);
        }
    }

    &__topic-item {
        position: relative;

        &--quiz {

        }

        &--summary {

            .topic-review__topic-item__questions.is-open {
                height: 10px;
            }
        }

        &--exercise {

            .topic-review__topic-item__questions.is-open {
                height: 10px;
            }
        }

        &--lesson {

            .topic-review__topic-item__questions.is-open {
                height: 10px;
            }
        }

        &__details {
            @extend .no-select;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid grey;
            padding: 5px 20px;
            margin-top: 10px;

            &:hover {
                .fa-chevron-down {
                    color: $palette-wet-asphalt;
                }
            }

            .fa-chevron-down {
                position: absolute;
                right: 0;
                top: 0;
                padding: 7px 17px;
                transition: color $transition;
            }
        }

        &__questions {
            margin: 0 4px;
            display: block;
            border: 1px solid $palette-clouds;
            white-space: nowrap;
            font-size: 13px;
            height: 0;
            overflow: hidden;
            transition: height $transition;

            &.is-open {
                height: auto;
            }

            &__answer {
                text-transform: capitalize;
            }

            &__question {
                p {
                    font-size: inherit;
                    display: inherit;
                }
            }
        }
    }

    &__questions {
        padding: 8px;
    }

    &__actions {
        margin-top: 24px;
        text-align: center;

        &__link {
            margin-right: 8px;
        }
    }
}
