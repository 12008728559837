$palette-yellow: #F7DB7B;
$palette-green: #70CD6D;
$palette-blue: #47a3da;
$palette-red: #e23840;
$palette-purple: #D17DF1;
$palette-orange: #EE946E;

$palette-yellow-light: lighten($palette-yellow, 10%); // #f8e6b3
$palette-yellow-dark: darken($palette-yellow, 10%); // #f1cb65
$palette-green-light: lighten($palette-green, 10%); // #72cb7b
$palette-green-dark: darken($palette-green, 10%); // #4ebe5c
$palette-blue-light: lighten($palette-blue, 10%); // #76badf
$palette-blue-dark: darken($palette-blue, 10%); // #368ec1
$palette-red-light: lighten($palette-red, 10%); // #e5636c
$palette-red-dark: darken($palette-red, 10%); // #c7182b
$palette-purple-light: lighten($palette-purple, 10%); // #e1aef0
$palette-purple-dark: darken($palette-purple, 10%); // #c159e2
$palette-orange-light: lighten($palette-orange, 10%); // #f1b5a0
$palette-orange-dark: darken($palette-orange, 10%); // #e66f4b

$palette-clouds: #ecf0f1;
$palette-page: #e6ebed;
$palette-silver: #bdc3c7;

$palette-asbestos: #7f8c8d;
$palette-wet-asphalt: #3d4e58;
$palette-midnight-blue: #262e33;

$palette-white: #fff;
$palette-off-white: #f5f5f5;
$palette-black: #000;

$palette-hover: $palette-clouds;
$palette-text-light: $palette-clouds;
$palette-text: $palette-asbestos;

//video
$logo-yellow: #e7c87b;
$beige: #D4AA7D;
$tuscan: #EFD09E;
$pastel: #D2D8B3;
$pewter: #90A9B7;

$twitter: #1b95e0;
$facebook: #3b5998;
$toast: #030303;
$toast-success: #51a351;
$toast-error: #bd362f;
$toast-info: $pewter;
$toast-warning: $palette-yellow-dark;

$grey-player: #e1e3e8;
$greo-player-border: #d7dbe2;
$palette-emerald: #cef6cd;
$palette-emerald-subtle: #c9dec8;
$palette-emerald-dark: #95da93;
