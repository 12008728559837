
.list-section {
    // color: $palette-midnight-blue;

    &--white {
        background-color: $palette-white;
    }

    &--grey {
        background-color: $palette-off-white;
    }

    &--dark {
        background-color: $palette-wet-asphalt;
        color: $palette-white;
    }

    &--green {
        background-color: $palette-green;
        color: $palette-white;

        .container .list-item {
            position: relative;
            padding: 0;
            border: 0!important;
            bottom: -2.375rem;
            padding: 0!important;

            @include bp-gte(md) {
                bottom: rem(-70px);
            }

            &:last-child {
                bottom: rem(-100px);
                padding: 0 0 rem(42px) 0!important;
            }

            @include bp-gte(lg) {
                padding: 0 0 rem(42px) 0!important;
            }
        }
    }

    &--yellow {
        background-color: $palette-yellow;
    }

    .list-item {
        padding: rem(42px) 0;
        border-bottom: 1px solid rgba($palette-silver, 0.8);

        @include bp-gte(lg) {
            // min-height: 270px;
            padding: rem(160px) 0;
        }

        &:last-child {
            border-bottom: 0;
        }

        &--white {
            background-color: $palette-white;
        }

        &--left, &--right {
            text-align: center;
        }

        &--left {
            p {
                margin-bottom: 0;
            }

            .list-item__image {
                margin: 0 auto;
            }

            .list-item__float-image {
                float: right;

                .no-touch & {
                    @include bp-gte(lg) {
                        transform: translatex(-80px);
                    }
                }
            }

            .list-item__float-text {

                .no-touch & {

                    @include bp-gte(lg) {
                        transform: translatex(80px);
                    }
                }
            }
        }

        &--right {
            .list-item__float-image {
                float: left;

                .no-touch & {
                    @include bp-gte(lg) {
                        transform: translatex(80px);
                    }
                }
            }

            .list-item__float-text {
                text-align: center;

                .no-touch & {
                    @include bp-gte(lg) {
                        transform: translatex(-80px);
                    }
                }

                @include bp-gte(lg) {
                    text-align: right;
                }
            }
        }

        @include bp-gte(lg) {
            border-bottom: 1px solid rgba($palette-silver, 0.2);

            &--left {
                text-align: left;
            }

            &--right {
                text-align: left;
            }
        }

        .grey {
            background-color: $palette-off-white;
        }

        &__float-image {
            width: 150%;
            float: right;
            transition: opacity $slow-transition, transform $slow-transition;

            .no-touch & {
                @include bp-gte(lg) {
                    opacity: 0;
                }
            }
        }

        &__float-text {
            transition: opacity $slow-transition, transform $slow-transition;

             .no-touch & {
                 @include bp-gte(lg) {
                     opacity: 0;
                 }
             }

            p {
                font-size: rem(16px);
            }

            @include bp-gte(lg) {
                padding: rem(24px);
            }
        }

        &__image2 {
            margin: 0 auto;
            max-width: rem(400px);
            height: auto;
            padding: 0 20px;
        }

        &__image {
            overflow: hidden;

            &--profile {
                border-radius: 50%;

                img {
                    width: auto;
                    height: rem(160px);

                    @include bp-gte(lg) {
                        height: rem(201px);
                    }
                }
            }
        }

        &__image {
            margin: 0 auto;
            width: rem(160px);
            height: rem(160px);
            display: block;
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;

            @include bp-gte(lg) {
                margin: 0 auto;
                width: rem(200px);
                height: rem(200px);
            }

            &.bird2 {
                background-image: url('../svgs/bird2.svg');
            }

            &.bird3 {
                background-image: url('../svgs/bird3.svg');
            }

            &.pattern {
                background-image: url('../svgs/pattern.svg');
            }

            &.notes {
                background-image: url('../svgs/notes.svg');
            }

            &.star2 {
                background-image: url('../svgs/star2.svg');
            }

            &.clef {
                background-image: url('../svgs/clef.svg');
            }

            &.newsletter {
                background-image: url('../svgs/upsell/upsell-newsletter.svg');
            }

            &.progress {
                background-image: url('../svgs/upsell/upsell-progress.svg');
            }

            &.sign-in {
                background-image: url('../svgs/upsell/upsell-sign-in.svg');
            }

            &.timeline {
                background-image: url('../svgs/upsell/upsell-timeline.svg');
            }
        }
    }

    &--equal-spacing {
        .list-item {
            padding: rem(42px) 0 0;
        }

        &:last-child .list-item {
            padding-bottom: rem(42px);
        }
    }
}

.list-section .grid-list {
    padding: rem(40px) 0;

    @include bp-gte(lg) {
        padding: rem(160px) 0;
    }

    &__item {
        padding: 0 0 rem(24px);

        @include bp-gte(md) {
            padding: 0 0 rem(100px);
        }

        &:last-child {
            padding: 0;
        }

        @include bp-gte(md) {
            &:nth-last-child(-n+2) {
                padding: 0;
            }
        }

        &__container {
            max-width: rem(445px);
            margin: 0 auto;
        }

        img {
            width: 60%;
        }

        &__desc {

            p {
                font-size: rem(14px);
            }
        }
    }
}
