.move-to-topic-item {
    position: absolute;
    min-width: 32px;
    padding: 0;

    &--next {
        top: 0;
        right: 0;
    }

    &--prev {
        top: 0;
        left: 0;
    }

    @include bp-gte(md) {
        min-width: rem(64px);
        padding: rem(12px) rem(16px);
        height: auto;
    }
    &.mdl-button {
        color: $palette-silver;
    }

    &--start {
        right: 0;
        color: $palette-white !important;
        background-color: darken($palette-emerald-dark, 10%);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: rem(54px);
        padding: 0 rem(16px);

        &.top {
            top: rem(32px);

            @include bp-gte(md) {
                display: none;
            }
        }

        &.bottom {
            bottom: rem(32px);
        }

        &:hover,
        &:active,
        &:focus,
        &:focus:not(:active) {
            background-color: $palette-emerald-dark;
        }
    }
}
