$bump-space: rem(32px);

@import '../icons';

.score-ui {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;

    @include bp-gte(lg) {
        overflow: visible;
    }

    &__toolbar {
        color: black;

        &__button {
          margin-right: 5px;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: $bump-space;
        right: 0;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        z-index: 4;
        cursor: default;

        @include bp-gte(lg) {
            left: 0;
        }
    }

    &__play-button {
        color: $palette-blue;
        position: absolute;
        right: rem(12px);
        font-size: rem(33px);
        width: rem(58px);
        height: rem(58px);
        border: rem(1px) solid $palette-silver;
        border-radius: 50%;
        line-height: inherit;
        transition: border $subtle-transition;

        .touch & {
            display: none;
        }

        &:disabled {
            opacity: 0.2;
            cursor: default;
            color: $palette-silver;
        }

        &:before {
            line-height: 0;
            display: inline-block;
            position: absolute;
            top: rem(29px);
            left: 0;
            right: 0;
        }

        i, span {
            &.fa-play {

                &:before {
                    position: relative;
                    left: rem(3px);
                }
            }
        }

        &__loading {
        }

        &.is-active {
            border: 2px solid rgba($palette-blue, 1);
            color: $palette-blue;
        }

        &--lesson {
            float: right;
            font-size: rem(18px);
            top: rem(-12px);
            right: 0;
            line-height: rem(36px);
            padding: 0;
            width: rem(42px);
            height: rem(42px);

            &:before {
                top: rem(20px);
            }
        }
    }

    svg {
        position: relative;
        z-index: 3;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        margin-left: $bump-space;

        @include bp-gte(lg) {
            margin-left: 0;
        }
    }
}

#instructions {
    margin: 50px auto;
    padding: 10px;
    border: 1px solid black;
    width: 625px;
}
