.multi-choice {

    &__score {
        padding-top: rem(68px);
    }

    &__options {
        padding-left: rem(30px);
    }

    &__option {
        display: block;
        padding-bottom: rem(8px);

        &__label {
            display: block;
            cursor: pointer;
            border: 4px solid;
            border-radius: rem(8px);
            border-color: $palette-asbestos;
            padding: rem(12px) rem(24px);
            transition: border-color $transition;

            &.mdl-radio.is-upgraded {
                padding-left: rem(40px);
            }

            &.is-checked {
                border-color: $palette-blue;
            }

            .mdl-radio {
                &__outer-circle {
                    top: rem(16px);
                    left: rem(13px);
                }

                &__inner-circle {
                    top: rem(21px);
                    left: rem(18px);

                    @include bp-gte(md) {
                        top: rem(20px);
                        left: rem(17px);
                    }
                }

                &__ripple-container {
                    top: 0px;
                    left: -4px;

                    @include bp-gte(md) {
                        top: rem(3px);
                        left: rem(0px);
                    }
                }
            }
        }
    }
}
