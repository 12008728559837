
.response {
    font-size: 18px;
    padding: 50px 0;

    i {
        display: block;
        font-size: 80px;
        margin-bottom: 24px;
    }

    &.success {
        color: $palette-emerald;
    }

    &.error {
        color: $palette-red;
    }

    &__message {
        margin-bottom: rem(12px);
    }
}
