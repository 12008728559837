.move-to-next-unit {
    display: block;
    color: $palette-white;
    height: 100%;
    min-width: rem(140px);
    width: 40%;
    text-transform: capitalize;

    @include bp-gte(md) {
        width: 220px;
    }

    // background-color: $palette-blue;
    //
    // &:hover,
    // &:active,
    // &:focus,
    // &:focus:not(:active) {
    //     background-color: lighten($palette-blue, 5%);
    // }


    &:disabled {
        background-color: $palette-asbestos;
        color: $palette-silver;
        cursor: default;
    }

    &--prev {
        float: left;
    }

    &--next {
        float: right;
    }

    &--end-of-item {
        background-color: $palette-green;

        &:hover,
        &:active,
        &:focus,
        &:focus:not(:active) {
            background-color: lighten($palette-green, 5%);
        }
    }
}
