.vextab-player {

    &__buttons {
        position: absolute;
        right: .75rem;
        top: rem(-16px);
        margin-right: rem(8px);

        .touch & {
            display: none;
        }

        @include bp-gte(md) {
            top: rem(-8px);
        }
    }

    &__button {
        position: relative;
        float: right;
        display: inline-block;
        top: 0;
        line-height: 1;

        .fa-play {
            left: -2px;
            position: relative;
        }

        .mdl-spinner {
            position: absolute;
            left: rem(6px);
            top: rem(6px);
            width: rem(25px);
            height: rem(25px);
        }

        &--piano {
            fill: $palette-silver;
            margin-right: rem(8px);
            transition: fill $transition;

            svg {
                width: 100%;
                height: auto;
            }

            &:hover,
            &:focus {
                opacity: 1;
            }

            &.is-playing {
                fill: $palette-yellow;
                opacity: 0.8;

                &:hover,
                &:focus {
                    opacity: 1;
                }
            }
        }

        &--play-pause {
            top: rem(-5px);
        }
    }


}
