$handler-button-radius: 8px;

.handler {
    // overflow: hidden;

    &--is-listening {

        .handler__error-overlay {
            .touch & {
                // display: block;
            }
        }
    }

    &__bump-buttons {
        position: absolute;
        left: 0;
        top: rem(45px);

        @include bp-gte(md) {
            top: rem(26px);
        }

        @include bp-gte(lg) {
            left: rem(-32px);
        }
    }

    &__bump-button {
        display: block;
        font-size: 1.5rem;
        padding: rem(2px) rem(8px);
        color: $palette-blue;
        transition: opacity $subtle-transition border $subtle-transition;
        border-radius: $handler-button-radius;

        &:disabled {
            opacity: 0.2;
            cursor: default;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: rgba($palette-blue, 0.2)
        }
    }

    &__error-overlay {
        display: none;
        background-color: rgba($palette-midnight-blue, 0.55);
        text-align: center;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 6;
        vertical-align: middle;
        color: $palette-off-white;
        border-radius: 3px;
        font-size: rem(16px);
        font-weight: bold;
        white-space: nowrap;

        .vertical-block__middle {
            position: relative;
        }

        &__note {
            font-size: rem(13px);
            position: absolute;
            left: 0;
            right: 0;
            bottom: rem(-10px);
        }
    }

    &#resize-container {
        position: relative;
        max-width: rem(800px);
        margin: auto;
    }
}
