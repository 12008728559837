$piano-container-trigger-width: rem(40px);
$piano-container-trigger-height: rem(40px);
$piano-container-padding-top: rem(4px);
$piano-container-padding-bottom: rem(4px);

.piano-container {
    background-color: $palette-wet-asphalt;
    display: inline-block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: $piano-z-index;
    transition: transform $transition;

    &--120 {
        height: rem(120px) + $piano-container-padding-top + $piano-container-padding-bottom;
        transform: translateY(rem(120px) + $piano-container-padding-top + $piano-container-padding-bottom);
    }

    &--160 {
        height: rem(160px) + $piano-container-padding-top + $piano-container-padding-bottom;
        transform: translateY(rem(160px));
    }

    &--open {
        transform: translateY(0px);
    }

    &__trigger {
        position: absolute;
        // left: calc(50% - (#{$piano-container-trigger-width}/2));
        right: rem(16px);
        top: rem(-32px);
        // width: $piano-container-trigger-width;
        opacity: 1;
        height: rem(32px);
        width: rem(32px);
        line-height: rem(32px);
        font-size: 2rem;
        background-color: $palette-wet-asphalt;
        color: $palette-white;
        display: none;

        .piano-container--open & {
            display: block;
        }

        &__icon {
        }

        svg {
            width: $piano-container-trigger-width;
            height: $piano-container-trigger-height;
        }
    }

    &__piano {
        padding: $piano-container-padding-top 0 $piano-container-padding-bottom;
    }
}
