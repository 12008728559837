$nav-section-width: 11.5rem;

.compose-input {
    border-radius: $page-border-radius;
    border-color: rgba($palette-asbestos, 0.2);
    padding: rem(8px);
    transition: border-color $subtle-transition;
    color: $palette-asbestos;
    border: 1px solid;
    width: 100%;
    background-color: $palette-white;

    &:active,
    &:focus {
        border-color: $palette-red;
        outline: 0;
    }
}

.compose-question {
    width: rem(400px);
    overflow: hidden;
    text-align: left;
    position: relative;
    background-color: rgba($palette-red, 0.2);

    @include bp-gte(sm) {
        width: 32rem;
    }

    @include bp-gte(md) {
        width: 35rem;
    }

    @include bp-gte(lg) {
        width: 44rem;
    }

    &__title {
        height: rem(50px);
        background-color: $palette-white;
        overflow: hidden;
        text-align: center;
        border-bottom: 1px solid $palette-red;

        h4 {
            line-height: rem(50px);
            margin-bottom: 0;
        }
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        padding: rem(16px) rem(18px);
        z-index: 2;

        .fa {
            background-color: $palette-white;
        }
    }

    &__section-container {
        // background-color: rgba($palette-red, 0.2);
        padding-top: rem(70px);
        position: relative;

        &--pad {
            padding: rem(32px);
        }
    }

    &__nav-sections {
        line-height: 1;
        text-align: left;
        padding: rem(16px) 0;
        position: absolute;
        top: 0;
        left: calc(50% - #{$nav-section-width}/2);
        width: rem(800px);
        transition: transform $transition;

        &--description {
            transform: translateX(-#{$nav-section-width*0});
        }

        &--question {
            transform: translateX(-#{$nav-section-width*1});
        }

        &--answer {
            transform: translateX(-#{$nav-section-width*2});
        }


    }

    &__nav-section {
        line-height: 1;
        display: inline-block;
        width: $nav-section-width;
        text-align: center;

        &:last-child {
            margin-right: 0;
        }

        h5 {
            margin-bottom: 0;
            text-transform: capitalize;
        }

        &__item {
            padding: rem(8px);
            opacity: 0.2;
            transition: color $transition, opacity $transition;

            &.is-active {
                color: $palette-red;
                opacity: 1;
            }
        }
    }

    &__description-section,
    &__question-section,
    &__answer-section {
        width: 100%;
        padding: 0 rem(24px) rem(12px);
        width: 100%;
        margin: 0 auto;

        h5 {
            margin: 0 0 0.6rem 0;
        }
    }

    &__description-section {
    }

    &__question-section {

    }

    &__answer-section {

    }

    .score-ui {
        margin-bottom: 0;
    }

    &__input {
        @extend .compose-input;
        display: block;
        padding-left: rem(14px);
        padding-right: rem(28px);
    }

    &__text-area {
        @extend .compose-input;
        resize: none;
    }

    &__score-container {
        background-color: $palette-white;
        border-radius: $page-border-radius;
        padding: rem(32px) rem(40px) rem(24px);
    }

    &__footer-bar {
        position: relative;
        height: rem(50px);
        padding: 0 rem(24px);
    }

    &__next-button {
        float: right;
    }

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($palette-black, 0.5);

        .spinner {
            bottom: rem(-25px);
            position: relative;
        }

        .mdl-spinner {
            width: rem(60px);
            height: rem(60px);
        }
    }

    &__published {
        padding: 2rem 2rem 3rem;

        &__title {
            text-align: center;
            margin-bottom: rem(18px);
        }

        &__send {
            margin-bottom: rem(32px);

            .invite-form {
                border-top: 0;

                &--sent,
                &--error {
                    background-color: $palette-white;
                }

                .input {
                    border-top: 0;
                    background-color: $palette-white;
                    border-radius: $page-border-radius;
                }
            }
        }

        &__or {
            max-width: rem(400px);
            margin: 0 auto;
            border-top: 1px solid $palette-asbestos;
            height: 1px;
        }

        &__try {
            margin-top: rem(32px);

            a {
                white-space: nowrap;
                 overflow: hidden;
                 text-overflow: ellipsis;
                 color: $palette-blue;
                 display: block;
                 background-color: $palette-white;
                 border-radius: $page-border-radius;
                 padding: rem(12px);

            }
        }
    }

    &__validation-error {
        color: $palette-red;
        padding-top: rem(8px);

        &--placeholder {
            // this maintains the modal width at 100%
            height: 1px;
            opacity: 0;
        }
    }

    &__next-section {

    }

    &__counter {
        display: inline-block;
        width: 59px;
        text-align: center;
        padding: 8px;
    }
}
