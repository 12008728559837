.lessonpage {
    position: relative;
    padding: 0 1rem 3rem;

    @include bp-gte(md) {
        padding: 0 3rem 3rem;
    }

    @include bp-gte(lg) {
        padding: 0 7rem 3rem;
    }

    &__next {
        position: absolute;
        right: 0;
        top: 2px;

        &.mdl-button--accent{
            color: $palette-silver;
        }

        &--active {
            color: $palette-white!important;
            background-color: darken($palette-emerald-dark, 10%);

            &:hover {
                background-color: $palette-emerald-dark;
            }
        }
    }

    &__next-prev {

    }

    &__progress {
        text-align: center;
        margin-bottom: rem(18px);
        position: relative;
    }

    &__list {
        display: inline-block;
        margin: rem(10px) rem(20px);
        text-align: center;

        &__item {
            width: rem(20px);
            height: rem(20px);
            border-radius: 50%;
            display: inline-block;
            margin-right: 8px;
            background-color: $palette-asbestos;
            cursor: pointer;

            &.is-active {
                background-color: $palette-blue;
            }
        }
    }

    &__slide {

        &__title {

            h2 {
                margin: 0;
            }

            &__badge {
                display: inline-block;
                width: rem(46px);
                height: rem(46px);
                margin-bottom: rem(28px);
                margin-right: rem(12px);
                vertical-align: top;

                @include bp-gte(md) {
                    width: rem(90px);
                    height: rem(68px);
                    margin-right: rem(25px);
                }
            }
        }
    }

    &__bottom-panel {
        height: rem(54px);
    }
}
