.handler-toolbar {

    &__button-group {
        display: inline-block;
        margin-right: rem(12px);
    }

    &__button {
        padding: rem(16px);
        position: relative;
        border: rem(2px) solid rgba($palette-blue, 0);
        border-radius: rem(8px);
        transition: border $subtle-transition;

        &:before {
            line-height: 0;
            display: inline-block;
            position: absolute;
            top: rem(14px);
            left: rem(11px);
        }

        &:disabled {
            opacity: 0.2;
            cursor: default;
        }

        &[class*=" musisync-"] {
            font-size: rem(32px);
        }

        &.is-active {
            border: 2px solid rgba($palette-blue, 1);
            color: $palette-blue;
        }
    }
}
