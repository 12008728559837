.hide {
    display: none!important;
}

.hide-mobile {
    @include bp-lte(sm) {
        display: none!important;
    }
}

.hide-mobile-tablet {
    @include bp-lte(md) {
        display: none!important;
    }
}

.hide-tablet-desktop {
    @include bp-gte(md) {
        display: none!important;
    }
}

.hide-desktop {
    @include bp-gte(lg) {
        display: none!important;
    }
}

.show {
    display: block;
}

.musie-hide {
    visibility: hidden;
    opacity: 0;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.sm--hide {@include bp-gte(sm) { display: none; }}
.md--hide {@include bp-gte(md) { display: none; }}
.lg--hide {@include bp-gte(lg) { display: none; }}
.xl--hide {@include bp-gte(sm) { display: none; }}
.xxl--hide {@include bp-gte(xxl) { display: none; }}
.sm--show {@include bp-gte(sm) { display: block; }}
.md--show {@include bp-gte(md) { display: block; }}
.lg--show {@include bp-gte(lg) { display: block; }}
.xl--show {@include bp-gte(xl) { display: block; }}
.xxl--show {@include bp-gte(xxl) { display: block; }}

.inline-block {
    display: inline-block;
}
.block {
    display: block;
}
.margin-center {
    margin: 0 auto;
}
.bold {
    font-weight: bold;
}
.italic {
    font-style: italic;
}
.relative {
    position: relative;
}

.text-center {
    text-align: center;
}
.center {
    text-align: center;
}

.rel-container {
    position: relative;
}
