.upsell-banner {
    display: block;
    padding: rem(24px);
    background-color: $palette-wet-asphalt;
    color: $palette-white;
    border-radius: rem(10px);
    margin-bottom: 18px;

    &__left {
        text-align: center;
        margin-bottom: rem(8px);

        .link {
            margin-bottom: rem(18px);

            @include bp-gte(md) {
                margin-bottom: 0;
            }
        }
    }

    &__right {
        text-align: left;
    }
}
