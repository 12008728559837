$player-height: 55px!default;

.player-module {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    // height: $player-height;
    z-index: 5;
    display: block;
    transition: 0.1s opacity linear;

    &__player {
        display: none;
        z-index: 8;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: rem(8px);
        background-color: $grey-player;
        // min-height: 250px;
        max-height: rem(590px);
        box-shadow: 0 rem(5px) rem(40px) rgba(0,0,0,.16);

        @include bp-gte(md) {
            bottom: rem(77px);
            right: rem(83px);
            width: rem(250px);
            left: calc(100% - rem(335px));
            padding: rem(16px) rem(24px);
            border: 1px solid $greo-player-border;
            border-radius: rem(3px);
        }

        &.open {
            display: block;
        }

        button {
            color: $palette-asbestos;

            &:hover {
                color: $palette-wet-asphalt;
            }
        }

        .page {
            height: 100%;
            padding: 0 rem(45px);
        }
    }
}
