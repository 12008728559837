.search-toggle {
    display: inline-block;
    position: relative;

    &__input-container {
        position: relative;
    }

    &__input {
        display: block;
        border: 2px solid;
        border-radius: rem(20px);
        border-color: rgba($palette-asbestos, 0);
        padding: rem(4px);
        width: rem(100px);
        transition: border-color $transition;
        color: $palette-asbestos;
        padding-left: rem(14px);
        padding-right: rem(28px);

        &:active,
        &:focus {
            border-color: lighten($palette-asbestos, 10%);
            outline: 0;
        }

        @include bp-gte(lg) {
            width: rem(240px);
        }
    }

    &__submit {
        font-size: 18px;
        position: absolute;
        display: inline-block;
        right: 0;
        top: 0px;
        padding: 6px 10px;
        color: $palette-asbestos;
    }

    &__results-container {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &__results {
        position: relative;
        height: 0;
        width: 0;

    }
}
