.stave-text-input {

    &__score {
        padding-top: rem(20px);
        padding-bottom: rem(8px);
    }

    &__text-input {
        display: block;
        position: relative;
        max-width: 30rem;
        margin: auto;
        margin: 0 auto;
        padding-bottom: rem(14px);

        &.is-in-ticked-state {

            &.is-correct input {
                color: $palette-green;
            }

            &.is-incorrect input {
                color: $palette-red;
            }
        }

        &__input {
            display: block;
            border: 4px solid;
            border-radius: rem(8px);
            border-color: $palette-asbestos;
            padding: rem(12px);
            width: 100%;
            transition: border-color $transition;

            &:active,
            &:focus {
                border-color: $palette-blue;
                outline: 0;
            }

            &.is-populated {
                border-color: $palette-blue;
            }
        }

        &__label {

        }
    }

    &__correct-answer {
        padding: rem(12px);
        color: $palette-green;
        display: block;
    }
}
