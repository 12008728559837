.text {

    &--blue {
        color: $palette-blue;
    }

    &--red {
        color: $palette-red;
    }

    &--yellow {
        color: $logo-yellow;
    }

    &--green {
        color: $palette-green;
    }
}
