.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $overlay-modal;

    &.is-padded {

        .modal__message {
            padding: rem(24px);
        }
    }

    &__message-block {
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        min-height: rem(600px);
        background-color: rgba($palette-black, 0.6);
    }

    &__message {
        background-color: $palette-white;
        border-radius: $page-border-radius;
        height: auto;
        margin: 0 auto;
        position: relative;
        max-width: rem(600px);
        position: relative;
        // bottom: rem(-75px);
    }

    &--fixed {
        .modal__message {
            overflow: hidden;
            bottom: 0;
            max-width: inherit;
        }

        .modal__middle {
            margin-top: rem(75px);

            @include bp-gte(md) {
                margin-top: rem(110px);
            }
        }
    }

    &__close {
        position: absolute;
        right: rem(-14px);
        top: rem(-14px);
        border-radius: 50%;
        width: rem(24px);
        height: rem(24px);
        @extend .paper;
        color: darken($palette-blue, 30%);
        background-color: $palette-white;

        .fa {
            display: block;
            font-size: rem(29px);
            line-height: 0;
            top: rem(12px);
            left: 0;
            right: 0;
            position: absolute;
        }
    }
}
