input.input-simple {
    background-color: #fff;
    border: 2px solid #c9c9c9;
    border-radius: 3px;
    display: inline-block;
    min-height: 36px;
    min-width: 50px;
    padding: 0 0.4em;
    vertical-align: middle;
    width: 100%;

    &:active,
    &:focus {
        border-color: #838383;
        outline: none;
    }
}

.input {
	position: relative;
	z-index: 1;
	display: block;
	margin: 0 auto;
	width: 100%;
	vertical-align: top;

	&.required:before {
		width: 6px;
	    height: 6px;
	    opacity: 0.8;
	    background-color: $palette-red;
	    border-radius: 50%;
	    top: 16px;
	    right: 2px;
	    content: "";
		position: absolute;
	}

	&--hoshi {
		overflow: hidden;
	}

	&__field {
		position: relative;
		display: block;
		float: right;
		padding: 0.8em;
		width: 60%;
		border: none;
		border-radius: 0;
		background: #f0f0f0;
		color: #aaa;
		font-weight: bold;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		-webkit-appearance: none; /* for box shadows to show on iOS */

		&:focus {
			outline: none;
		}

		&--hoshi {
			margin-top: 1em;
			padding: 0.85em 0.15em;
			width: 100%;
			background: transparent;
			color: #595F6E;
		}
	}

	&__label {
		display: inline-block;
		float: right;
		padding: 0 1em;
		width: 40%;
		color: #6a7989;
		font-weight: bold;
		font-size: 70.25%;
		-webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&--hoshi {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 0 0.25em;
			width: 100%;
			height: calc(100% - 1em);
			text-align: left;
			pointer-events: none;

			&::before, &::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: calc(100% - 10px);
				border-bottom: 1px solid #B9C1CA;
			}

			&::after {
				margin-top: 2px;
				border-bottom: 4px solid red;
				-webkit-transform: translate3d(-100%, 0, 0);
				transform: translate3d(-100%, 0, 0);
				-webkit-transition: -webkit-transform 0.3s;
				transition: transform 0.3s;
			}

			&-color-1::after {
				border-color: $palette-blue;
			}
		}
	}

	&__label-content {
		position: relative;
		display: block;
		padding: 1.6em 0;
		width: 100%;

		&--hoshi {
			position: absolute;
		}
	}

	&.submit {
		margin-top: 25px;
	}

	textarea {
	    width: 100%;
	}
}

.input__field--hoshi:focus + .input__label--hoshi::after,
.input--filled .input__label--hoshi::after {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
.input--filled .input__label-content--hoshi {
	-webkit-animation: anim-1 0.3s forwards;
	animation: anim-1 0.3s forwards;
}

@-webkit-keyframes anim-1 {
	50% {
		opacity: 0;
		-webkit-transform: translate3d(1em, 0, 0);
		transform: translate3d(1em, 0, 0);
	}
	51% {
		opacity: 0;
		-webkit-transform: translate3d(-1em, -40%, 0);
		transform: translate3d(-1em, -40%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, -40%, 0);
		transform: translate3d(0, -40%, 0);
	}
}

@keyframes anim-1 {
	50% {
		opacity: 0;
		-webkit-transform: translate3d(1em, 0, 0);
		transform: translate3d(1em, 0, 0);
	}
	51% {
		opacity: 0;
		-webkit-transform: translate3d(-1em, -40%, 0);
		transform: translate3d(-1em, -40%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, -40%, 0);
		transform: translate3d(0, -40%, 0);
	}
}
