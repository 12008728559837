.bottom-banner {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background-color: $palette-midnight-blue;
    color: $palette-white;
    z-index: 10;
    transition: bottom 1s ease-out;

    p {
        font-size: rem(13px);

        @include bp-gte(md) {
            font-size: inherit;
        }
    }

    &.active {
        bottom: 0%;
    }

    &__container {
        position: relative;
        padding: rem(32px);
        padding: rem(16px) rem(32px) rem(16px) rem(48px);
        font-size: rem(13px);
    }

    &__close {
        position: absolute;
        top: 0;
        left: 0;
        color: $palette-white;
        text-align: center;
        line-height: rem(32px);
        font-size: rem(32px);
        padding: rem(8px) rem(16px);
    }

    &__left {
        padding-right: rem(20px);
    }

    &__right {
        padding-right: rem(20px);
        padding-top: rem(12px);
    }

    .mce_inline_error {
        margin: 0!important;
    }
}

#mc_embed_signup div.mce_inline_error {
    font-size: 0.8em;
}

.simple-text {
    font-size: 0.8em;
    padding-top: rem(8px);
}
