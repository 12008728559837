.purchase-banner {
    display: block;
    background-color: $logo-yellow;
    color: #fff;
    padding: 24px 58px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top-left-radius: $page-border-radius;
    border-top-right-radius: $page-border-radius;
    z-index: $page-item-z-index;

    h4 {
        margin-bottom: 0;
        line-height: 2.25rem;
    }

    &__actions {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__actions-list {

        &__item {
            display: inline-block;
            vertical-align: top;
            margin-right: rem(12px);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__button {

    }
}
