.topic-progress {
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;

    &__link {
        display: inline-block;
        border-radius: 2px;
        margin-left: rem(4px);
        vertical-align: middle;

        &--lesson {
            cursor: pointer;

            & .topic-progress__link__blob {
                &:hover,
                &:active,
                &:focus {
                    background-color: lighten($palette-asbestos, 5%);
                }
            }
        }

        &--quiz {
            cursor: default;
            // opacity: 0.6;
        }

        &.is-before .topic-progress__link__blob {
            background-color: $palette-blue;

            .topic-progress__link--lesson & {
                &:hover,
                &:active,
                &:focus {
                    background-color: lighten($palette-blue, 5%);
                }
            }
        }

        &.is-active .topic-progress__link__blob {
            background-color: $palette-blue;

            &:before {
                content: "\f111";
                color: $palette-white;
            }
        }

        &.is-correct .topic-progress__link__blob {
            // background-color: $topic-item-correct;

            &:before {
                content: "\f00c";
                color: $palette-white;
            }
        }

        &.is-incorrect .topic-progress__link__blob  {
            // background-color: $topic-item-incorrect;

            &:before {
                content: "\f00d";
                color: $palette-white;
            }
        }

        &__blob {
            width: rem(28px);
            height: rem(24px);
            line-height: rem(24px);
            border-radius: 2px;
            background-color: $palette-asbestos;
            display: block;
            transition: background-color $subtle-transition;
        }
    }
}
