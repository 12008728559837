.topic-item-bar {
    text-align: center;
    position: relative;
    padding: rem(60px) 0 0;

    &__list {
        display: inline-block;
        margin: rem(5px) rem(12px);
    }

    &__previous {
        position: absolute;
        left: rem(32px);
        top: 2px;
        min-width: 32px;
        padding: 0;

        @include bp-gte(md) {
            min-width: rem(64px);
            padding: 0 rem(16px);
        }

        &.mdl-button--accent{
            color: $palette-silver;
        }

        &--active {
            color: $palette-white!important;
            background-color: darken($palette-emerald-dark, 10%);

            &:hover {
                background-color: $palette-emerald-dark;
            }
        }
    }

    &__link {
        display: inline-block;
        border-radius: 50%;
        margin-right: rem(4px);
        padding: rem(8px);

        &--lesson {
            cursor: pointer;
        }

        &--quiz {
            cursor: default
        }

        &.is-correct .topic-progress__link__blob {
            background-color: $topic-item-correct;
        }

        &.is-incorrect .topic-progress__link__blob  {
            background-color: $topic-item-incorrect;
        }

        &__blob {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $palette-clouds;
            display: block;
            transition: background-color $subtle-transition;

            &.is-active {
                background-color: $palette-blue;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
