.quiz {
    .passed {
        color: $palette-green;
    }

    .failed {
        color: $palette-red;
    }

    &__question {

        &__ask {
            @extend .no-select;
            background-color: $palette-clouds;
            height: rem(200px);
            position: relative;
            overflow: hidden;
            border-bottom-left-radius: $page-border-radius;
            border-bottom-right-radius: $page-border-radius;

            @include bp-gte(md) {
                height: 90px;
            }

            &__desc {
                min-height: rem(100px);


                @include bp-gte(md) {
                    height: 100%;
                    min-height: initial;
                }

                &__text {
                    padding: rem(20px);
                }
            }

            &__check {
                height: rem(80px);
                position: relative;
                right: -1px;

                @include bp-gte(md) {
                    height: 100%;
                }
            }

            &__qNum {
                font-weight: bold;
            }
        }

        &__button {
            width: 100%;
            display: block;
            color: $palette-white;
            background-color: $palette-green;
            height: 100%;

            &:hover,
            &:active,
            &:focus {
                background-color: rgb(62, 182, 113);
            }

            &:disabled {
                background-color: $palette-asbestos;
                color: $palette-silver;
                cursor: default;
            }
        }
    }

    &__tickStateText {
        font-weight: bold;
        height: 100%;
        padding: rem(20px) 0;

        &__container {
            height: 100%;

            &__icon {
                height: 100%;
                font-size: rem(40px);
                line-height: rem(47px);
                padding: 0 rem(16px);

                .vertical-layout {
                    height: 100%;
                }

                &__box {
                    background-color: $palette-white;
                    width: rem(50px);
                    height: rem(50px);
                    border-radius: 50%;
                    text-align: center;
                    margin: 0 auto;
                }
            }

            &.is-correct &__icon {
                color: $topic-item-correct;
            }

            &.is-incorrect &__icon {
                color: $topic-item-incorrect;
            }
        }

        &__text {
            height: 100%;
        }
    }
}
