.quiz-complete {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 16px 16px;

    @include bp-gte(md) {
        padding: 0 40px 40px;
    }

    .btn {
        margin-top: rem(8px);
        margin-right: rem(8px);
    }

    &__social {
        margin-top: rem(12px);
    }

    &__social-button {
        display: inline-block;
        margin-right: 8px;
    }

    &__table {
        width: 100%;
        border: 0;

        thead {
            font-weight: bold;
        }

        tbody {
            tr:hover,
            tr:active,
            tr:focus {
                background-color: inherit;
            }

            tr:nth-child(odd) {
                background-color: #eee;

                tr:hover,
                tr:active,
                tr:focus {
                    background-color: #eee;
                }
            }

            td {
                border: 0;

                p {
                    font-size: inherit;
                    display: inherit;
                }
            }
        }
    }

    &__score {
        margin-bottom: rem(12px);
        font-size: rem(28px);
        line-height: 1;

        &__value {
            font-size: rem(88px);
        }

        &__pass-fail {
            font-size: rem(18px);
            margin-bottom: rem(8px);
        }

        .passed,
        .failed {
            padding-left: rem(8px);
        }
    }

    &__container {

        &__vl {
            margin-bottom: rem(28px);
        }

        &__desc {
            @include bp-gte(lg) {
                padding-right: 20px;
            }

            p {
                font-size: rem(24px);
            }
        }

        h2 {
            text-align: center;
        }
    }

    &__actions {

        &.vertical-layout__column.mob-block {
            margin-bottom: 0;
        }

        @include bp-gte(lg) {
            border-left: 1px solid $palette-clouds;
            vertical-align: top;
            padding: 10px 20px;
        }
    }

    &--user-question {

        .quiz-complete__message {


            &__details {
                padding: rem(40px) rem(40px) 0;
                overflow: hidden;

                li {
                    line-height: 1;
                    display: block;
                }
            }

            &__icon {
                font-size: rem(80px);
                text-align: center;
                padding: 5.5rem 5.5rem 0;

                &.is-correct {
                    color: $palette-green;
                }

                &.is-incorrect {
                    color: $palette-red;
                }
            }
        }

        .upsell-banner {
            margin-top: rem(32px);
        }
    }

    &__retry {
        display: block;
        text-align: center;
    }
}
