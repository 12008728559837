.quiz-tip {
    display: none;
    position: relative;
    height: rem(68px);
    @extend .no-select;

    @include bp-gte(md) {
        display: block;
    }

    > div {
        height: 100%;
    }

    &__character {
        width: rem(70px);
        position: absolute;
        right: rem(32px);
        bottom: rem(-6px);
        vertical-align: bottom;
        z-index: 1;
    }

    &__tip {
        color: $palette-white;
        background-color: $palette-wet-asphalt;
        padding: rem(8px) rem(12px);
        border-radius: 16px;
        min-height: 52px;
        max-width: 640px;
        margin-right: rem(40px);
        margin-bottom: 0;
        margin-top: 0;
        float: left;
        min-width: rem(200px);
        font-size: rem(12px);
        transition: opacity 0.5s linear;
        z-index: 10;

        @include bp-gte(lg) {
            font-size: rem(14px);
        }

        &::after {
            content: "";
            position: absolute;
            border-style: solid;
            display: block;
            width: 0;
            top: rem(16px);
            left: rem(-49px);
            bottom: auto;
            border-width: rem(10px) rem(50px) rem(10px) 0;
            border-color: transparent $palette-wet-asphalt;
        }
    }
}
