.title {
    color: $palette-white;

    &--blue {
        background-color: $palette-blue;
    }

    &__content {
        height: rem(250px);
        position: relative;
    }

    &__text {
        position: absolute;
        bottom: rem(24px);
        text-shadow: 2px 1px 0 $palette-blue, 5px 3px 0 rgba(0,0,0,0.15);
    }

    &__links {
        display: block;
        position: absolute;
        right: 0;
        bottom: rem(32px);
    }

    &__link-item {
    }

    &__link {
        display: inline-block;
        padding: rem(8px);
        font-size: rem(24px);
    }
}
