@import 'breakpoints';

.vertical-block {
    display: inline-block;
    height: 100%;
    width: 100%;
    text-align: center;

    &:before {
        display: inline-block;
        vertical-align:middle;
        height:100%;
        content: "";
    }

    &__middle {
        display: inline-block;
    }
}

.vertical-layout {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;

    .pad {
        padding: 0;

        @include bp-gte(md) {
            padding: 0 30px;
        }
    }

    &__middle {
        width: 100%;
        display: block;

        @include bp-gte(lg) {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__middle-all {
        display: table-cell;
        vertical-align: middle;
    }

    &__column {
        margin-bottom: 0;
        display: table-cell;
        vertical-align: middle;
        height: 100%;

        &.top {
            vertical-align: top;
        }

        &.bottom {
            vertical-align: bottom;
        }

        &.col-u {
            &-1-2 {
                width: 50%;
            }
            &-1-3 {
                width: 34%;
            }
            &-2-3 {
                width: 66%;
            }
            &-1-4 {
                width: 25%;
            }
            &-3-4 {
                width: 75%;
            }
            &-2-5 {
                width: 40%;
            }
            &-3-5 {
                width: 60%;
            }
            &-1-5 {
                width: 20%;
            }
            &-4-5 {
                width: 80%;
            }
            &-1-8 {
                width: 12.5%;
            }
            &-7-8 {
                width: 77.5%;
            }
        }

        @include bp-gte(sm) {
            &.col-u-sm {
                &-1-2 {
                    width: 50%;
                }
                &-1-3 {
                    width: 34%;
                }
                &-2-3 {
                    width: 66%;
                }
                &-1-4 {
                    width: 25%;
                }
                &-3-4 {
                    width: 75%;
                }
                &-2-5 {
                    width: 40%;
                }
                &-3-5 {
                    width: 60%;
                }
            }
        }

        @include bp-gte(md) {
            &.col-u-md {
                &-1-2 {
                    width: 50%;
                }
                &-1-3 {
                    width: 34%;
                }
                &-2-3 {
                    width: 66%;
                }
                &-1-4 {
                    width: 25%;
                }
                &-3-4 {
                    width: 75%;
                }
                &-2-5 {
                    width: 40%;
                }
                &-3-5 {
                    width: 60%;
                }
            }
        }

        @include bp-gte(lg) {
            &.col-u-lg {
                &-1-2 {
                    width: 50%;
                }
                &-1-3 {
                    width: 34%;
                }
                &-2-3 {
                    width: 66%;
                }
                &-1-4 {
                    width: 25%;
                }
                &-3-4 {
                    width: 75%;
                }
                &-2-5 {
                    width: 40%;
                }
                &-3-5 {
                    width: 60%;
                }
            }
        }

        &.mob-block {
            height: auto;

            @include bp-lte(md) {
                display: block;
                width: 100%;
                margin-bottom: rem(24px);
            }
        }
    }
}
