.list-item-user {
    display: block;
    position: relative;
    min-height: rem(57px);
    line-height: 1;

    &:last-child .search-results__results-group__result__link {
        border-bottom: 0;
    }

    &__sep-links {
        padding: rem(8px) 0;
    }

    &__link {
        padding: rem(8px) 0;
        border-bottom: 1px solid $palette-clouds;
        display: block;
    }

    &__pic-container {
        text-align: center;
    }

    &__pic {
        width: rem(40px);
        display: inline-block;
    }

    &__request-buttons {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 8px;
        padding: 10px 0;
        z-index: 2;

        .button-simple {
            padding: 8px;
            line-height: inherit;
            margin-right: 8px;
        }

        .spinner {
            margin-right: rem(42px);
            padding: rem(2px) 0 0;
        }
    }

    &__empty-result {
        padding: .75rem .75rem .5rem;
        border-bottom: 1px solid $palette-asbestos;
        display: block;

        &:last-child {
            border-bottom: 0;
        }
    }
}
