.error-message {
    padding: 0 rem(16px);

    &__message {
        background-color: $palette-red;
        border-radius: 20px;
        color: $palette-white;
        padding: rem(20px);
        max-width: 50rem;
        margin: rem(18px) auto;
    }
}
