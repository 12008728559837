$card-width-mobile: rem(300px);
$card-width: rem(500px);
$card-height: rem(297px);

.card {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($palette-clouds, 0.5);

    &--badge {
        .card__item {
            border: 3px solid $palette-red;
            text-align: center;
        }

        .card__title {
            background: url('../images/card-background.png') center / cover;
            background-color: $palette-red;
            padding: rem(16px) rem(16px) rem(32px) rem(16px);
        }

        .card__actions {
            text-align: center;
        }

        .card__actions__btn {
            background-color: $palette-red;

            &:hover,
            &:active,
            &:focus,
            &:focus:not(:active) {
                background-color: lighten($palette-red, 10%);
            }
        }
    }

    &--warning {
        .card__item {
            border: 3px solid $palette-yellow;
            text-align: center;
        }

        .card__title {
            background: $palette-yellow;
            height: auto;
            padding: rem(24px) rem(16px);
        }

        .card__actions {
            text-align: center;
        }

        .card__icon {
            margin-bottom: rem(16px);
        }

        .card__actions__btn {
            background-color: $palette-yellow;

            &:hover,
            &:active,
            &:focus,
            &:focus:not(:active) {
                background-color: lighten($palette-yellow, 10%);
            }
        }

        h3 {
            margin-bottom: 0;
        }
    }

    &__container {
        position: relative;
        height: 100%;
    }

    &__close {
        position: absolute;
        right: rem(-14px);
        top: rem(-14px);
        border-radius: 50%;
        width: rem(24px);
        height: rem(24px);
        @extend .paper;
        color: darken($palette-blue, 30%);
        background-color: $palette-white;

        .fa {
            display: block;
            font-size: rem(29px);
            line-height: 0;
            top: rem(12px);
            left: 0;
            right: 0;
            position: absolute;
        }
    }

    &__item {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -$card-height/2;
        margin-left: -$card-width-mobile/2;
        z-index: $card-z-index;
        @extend .paper;
        text-align: left;
        background-color: $palette-white;
        width: $card-width-mobile;
        border: 3px solid $palette-blue;

        @include bp-gte(lg) {
            margin-left: -$card-width/2;
            width: $card-width;
        }
    }

    &__title {
        color: $palette-white;
        height: rem(215px);
        background: $palette-blue url('../images/card-background-blue.png') center / cover;
        padding: rem(32px) rem(16px) rem(32px) rem(16px);

        h2 {
            margin-bottom: rem(8px);
        }
    }

    &__icon {
        margin: 0 auto;
        width: rem(80px);
        margin-bottom: rem(8px);
    }

    &__menu {
        color: $palette-white;
    }

    &__actions {
        padding: rem(16px);
        text-align: right;

        &__btn {
            color: $palette-white!important;
            background-color: $palette-blue;

            &:hover,
            &:active,
            &:focus,
            &:focus:not(:active) {
                background-color: lighten($palette-blue, 10%);
            }
        }
    }
}
