.intropage,
.main-promo {
    &__angle {
        height: rem(60px);
        width: 100%;
    }
}

.intropage {

    &__section {
        width: 100%;
    }

    &__video-section {
        padding: 3rem 0;

        @include bp-gte(md) {
            padding: 7rem 0;
        }

        iframe {
            width: 100%;
            max-width: rem(640px);
            margin: 0 auto;

            @include bp-lte(sm) {
                height: 240px;
            }
        }
    }

    .button-section {
        text-align: center;
        background: $palette-midnight-blue;
        color: $palette-off-white;

        p.last {
            margin-bottom: rem(24px);
        }

        img {
            width: 80%;
        }

        @include bp-gte(lg) {
            text-align: left;

        }
    }

    .mce_inline_error {
        margin: 0!important;
    }

    .button-simple {
        margin-top: rem(8px);
    }

    &__get-started {
        text-align: center;

        .btn {
            margin-bottom: 0;
        }

        .list-item {
            padding: rem(40px);
            position: relative;
            top: rem(-12px);
        }
    }
}
